import styled from "styled-components";
import { colors } from '../../../helpers/style/constants';

export const Form = styled.form`
  display: flex;
  justify-content: space-between;
`;

export const Input = styled.input`
  display: none;
`;

export const Container = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  width: 150px;
  height: 150px;
  transition: .1s linear;
  background: ${p => p.selected === p.label ? "#f8f8f8" : "white"};
  border: ${p => p.selected === p.label ? "1px solid #18187A" :  "1px solid #c7cdd1"};
  cursor: pointer;
  &:hover {
    background: ${colors.very_very_light_grey};
  }

  & > svg {
    height: 30px;
    width: 30px;
    stroke: ${colors.light_purple};
  }
`


export const Text = styled.p`
  font-size: 15px;
  font-weight: 400;
  text-transform: capitalize;
  margin: 0;
  color: ${colors.marjory_blue};
`


export const Spacer = styled.div`
  height: ${p => p.height ? `${p.height}` : ""};
`;
