import axios from 'axios';
import { getConfig } from '../helpers/getConfig';
import * as mapper from "./mapper";
const FACTORY_API_URL = process.env.REACT_APP_FACTORY_API_URL;

export const getDeployments = async (token) => {
  try {
    const config = getConfig(token);
    const deploymentsList = await axios.get(
      `${FACTORY_API_URL}/deployments`,
      config
    );
    return mapper.deployments(deploymentsList.data);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getdeployment = async ({token, deploymentId}) => {
  try {
    const config = getConfig(token);
    const result = await axios.get(
      `${FACTORY_API_URL}/deployments/${deploymentId}`,
      config
    );
    return mapper.deployment(result.data)
  } catch (err) {
    console.error(err);
    throw err;
  }
};

