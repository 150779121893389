import { createContext, useEffect, useState } from "react";

import Keycloak from "keycloak-js";
import Spinner from "../components/Loader/Spinner";

let url = window.location.href;
const posHttp = url.indexOf('://');
if (posHttp !== -1) {
  url = url.substring(posHttp + 3)
}

// Mode local
const posLocalhost = url.indexOf('localhost');
const isLocal = posLocalhost === 0;

let keyCloackRealm;
if (isLocal) {
  const pathArray = url.split('/');
  keyCloackRealm = pathArray[1];
} else {
  const pathArray = url.split('.');
  keyCloackRealm = pathArray[0];
}

// Remove anchor
const posAnchor = keyCloackRealm.indexOf('#');
if (posAnchor !== -1) {

  keyCloackRealm = keyCloackRealm.substring(0, posAnchor);
}

let computeBaseName = isLocal ? keyCloackRealm : "";

// case marjory realm = operation
// if (keyCloackRealm === 'operation') {
//   keyCloackRealm = 'marjory'
// }

const keycloak = Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: keyCloackRealm,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
  credentials: {
    secret: process.env.REACT_APP_KEYCLOAK_CLIENT_SECRET
  }
});

export const KeycloakContext = createContext();

const AuthWrapper = ({ children, baseName }) => {

  const [state, setState] = useState({
    keycloak: null,
    username: null,
    userRoles: null,
    authenticated: false,
    baseName: computeBaseName
  });

  useEffect(() => {
    return baseName(state.baseName);
  }, [state, baseName])

  useEffect(() => {
    async function fetchData() {
      keycloak.init({ onLoad: "login-required", checkLoginIframe: false })
        .success(authenticated => {
          setState({
            keycloak: keycloak,
            username: keycloak.idTokenParsed.given_name,
            reviewerName: keycloak.idTokenParsed.name,
            userRoles: keycloak.resourceAccess.factory?.roles,
            authenticated: authenticated,
            baseName: computeBaseName
          });
        });
    }

    fetchData();
  }, []);

  if (state.keycloak) {
    if (state.authenticated) {
      return (
        <KeycloakContext.Provider value={[state, setState]}>
          {children}
        </KeycloakContext.Provider>
      );
    }
    return (
      <div>Unable to authenticate</div>
    );
  }

  return (
    <Spinner />
  );
}

export default AuthWrapper;
