import * as sc from "./style";

export const AppSelect = ({ appWithVersion, versions, selected, handleChange }) => {

  const getSortedVersion = () => {
    const reg = new RegExp('[a-zA-Z]+');
    if (reg.test(versions[0]?.versionNumber)) {
      return versions.map( version => {
        return version.versionNumber;
      })
    }

    let arrayOfVersions = [];
    versions.map(item => arrayOfVersions.push(item.versionNumber));
    return arrayOfVersions.map( a => a.split('.').map( n => + n + 100000 ).join('.')).sort()
      .map( a => a.split('.').map( n => + n - 100000 ).join('.') );
  }

  const getDefaultValue = () => {
    const version = versions.find(item => item.versionId === selected);
    return (version ? version.versionNumber : versions[0]?.versionNumber);
  }

  return (
    <sc.Select onChange={handleChange} defaultValue={appWithVersion || getDefaultValue()}>
      {getSortedVersion().map(item => {
          return (
            <option key={item} value={item}>
              {`Version ${item}`}
            </option>
          )
      })}
    </sc.Select>
  )
}