import * as sc from "./style";

const Spinner = () => {
  return (
    <sc.Wrapper>
      <sc.StyledSpinner viewBox="0 0 50 50">
        <circle
          className="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="2"
        />
      </sc.StyledSpinner>
    </sc.Wrapper>
  );
};

export default Spinner;