import styled from "styled-components";
import { colors } from "../../helpers/style/constants";

export const TagContainer = styled.div`
  width: ${p => p.label === "CUSTOMER" ? "100px" : "80px"};
  height: ${p => p.label === "in review"
    ? "28px"
    : p.label === "module tag"
      ? "28px"
      : "24px"
  };
  color: ${p => p.label === "in review"
      ? `${colors.tag_primary_label}`
    : p.label === "module tag"
      ? `${colors.tag_secondary_label}`
    : (p.label === "private") || (p.label === "TENANT")
      ? "#ff5605"
    : (p.label === "public") || (p.label === "PUBLIC")
      ? "#057bff"
    : p.label === "group"
      ? "#39CB90"
      : "#79869e"
  };
  background-color: ${p => p.label === "in review"
    ? `${colors.tag_primary_bg}`
    : p.label === "module tag"
      ? `${colors.tag_secondary_bg}`
    : (p.label === "private") || (p.label === "TENANT")
      ? "#fff1eb"
    : (p.label === "public") || (p.label === "PUBLIC")
      ? "#f0f7ff"
    : p.label === "group"
      ? "#DFFBEC"
      : "#eef2f8"
  };
  padding: 4px 10px;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  display: flex;
  text-transform: ${p => p.label === "module tag"
    ? "capitalize"
    : "uppercase"
  };
  font-size: ${p => p.label === "module tag"
    ? "16px"
    : "11px"
  };
  border-radius: 5px;

  svg {
    width: 20px;
  }

  span {
    margin-left: 7px;
  }
`;
