import styled from "styled-components";
import { colors } from "../../helpers/style/constants";

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${props => props.primary
    ? `1px solid ${colors.primary_btn_border}`
    : `1px solid ${colors.secondary_btn_border}`
  };
  border-radius: 8px;
  background-color: ${props => props.primary
    ? colors.primary_btn
    : colors.secondary_btn
  };
  color: ${props => props.primary
    ? colors.primary_btn_text
    : colors.secondary_btn_text
  };
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  min-width: ${props => props.buttonType === "squaredIcon"
    ? "54px"
    : props.small
    ? "200px"
    : "280px"
  };
  outline: none;
  padding: 14px 0;
  transition: 0.1s ease-out;
  width: ${props => props.block
    ? "100%"
    : props.buttonType === "squaredIcon"
    ? "54px"
    : ""
  };
  height: ${props => props.block
    ? "50px"
    : ""
  };
  &:hover {
    background-color: ${props => props.primary
    ? colors.primary_btn_hover
    : colors.secondary_btn_hover
  };
  }
  &:active {
    transform: ${p => !p.disabled
    ? "scale(0.95)"
    : "none"
  };
  }
  &:disabled {
    background-color: #e4e8f0;
    border-color: #e4e8f0;
  }
`;

export const Icon = styled.span`
  display: flex;
  margin: ${props => props.buttonType
    ? "0 0"
    : "0 10px 0 0"
  };
  &:first-child > svg {
  }
`;

export const BorderlessButton = styled.button`
  background-color: ${colors.borderless_btn};
  border: none;
  cursor: pointer;
  outline: none;
  transition: 0.4s ease-out;
  min-width: 36px;
  min-height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 36px; 
  &:hover{
    /* background-color: ${colors.borderless_btn_hover}; */
  }
`;

export const Spinner = styled.div`
  padding: 9px;
  font-size: 10px;
  animation: load8 .9s infinite linear;
  transform: translateZ(0);
  border: 3px solid rgba(255, 255, 255, 0.2);
  border-left: 3px solid #020253;
  border-radius: 68px;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;
