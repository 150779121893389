import * as sc from "./style";
import * as Icons from "react-feather";

export const VersionRadio = ({options, handleChange, selected}) => {
  const getIcon = (icon) => {
    const IconTag = Icons[icon];
    return (
      <IconTag />
    );
  };

  return (
    <sc.Form>
      {
        options.map((option, i) => {
          return (
            <sc.Container
              key={i}
              htmlFor={option.value}
              selected={selected}
              label={option.value}
            >
              {getIcon(option.icon)}
              <sc.Spacer height="4px"/>
              <sc.Input
                id={option.value}
                type="radio" 
                value={option.value}
                name="version"
                onClick={handleChange}
              ></sc.Input>
              <sc.Text>{option.value}</sc.Text>
            </sc.Container>
          )
        })
      }
    </sc.Form>
  )
}