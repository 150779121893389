import React from "react";
import PropTypes from "prop-types";
import * as sc from "./Logo.style";
import { Camera } from "react-feather";

export const Logo = ({ name, url, size, editable, onOpenUploadImageModal, ...props }) => {

  const hasLogo = () => {
    const firstLetter = name.charAt(0).toUpperCase();
    if (!url) {
      return firstLetter;
    }
    return (
      <sc.Img src={url} alt={name} size={size} />
    )
  }

  if (editable) {
    return (
      <sc.LogoBorder size={size}>
        <sc.LogoContainer size={size} url={url}>
          {hasLogo()}
        </sc.LogoContainer>
        <sc.Overlay onClick={onOpenUploadImageModal}><Camera /></sc.Overlay>
      </sc.LogoBorder>
    )
  }

  return (
    <sc.LogoBorder size={size}>
      <sc.LogoContainer size={size} url={url}>
        {hasLogo()}
      </sc.LogoContainer>
    </sc.LogoBorder>
  )
};

Logo.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["small", "medium", "large"]),
};

Logo.defaultProps = {
  url: null,
  name: "Mangopay",
  size: "large"
};