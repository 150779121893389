import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as sc from "./Expandable.style";

export const ExpandableText = ({ children, maxLength = 240, onClick }) => {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);

  const textSize = !children ? 0 : children.length;
  const fullText = !children ? '' : children;

  // 001: Truncate text if longer than specified maxLength
  const truncatedText = !children
    ? ''
    : textSize <= maxLength
      ? children.substring(0, maxLength)
      : children.substring(0, maxLength) + "...";

  return (
    <>
      <sc.Paragraph onClick={onClick}>
        {
          showMore
            ? fullText
            : truncatedText
        }
      </sc.Paragraph>
      {textSize > maxLength &&
        // 002: Hide show more button if text length is less than specified maxLength 
        <sc.ShowMoreBtn onClick={() => setShowMore(!showMore)}>
          {showMore
            ? t("show_less")
            : t("show_more")
          }
        </sc.ShowMoreBtn>
      }
    </>
  );
};