import View from "./View";
import { selectVersions, getNextVersion } from "./mapper";
import { useState } from "react";

export const Step2 = ({ changeType, values, onNext, onPrevious, onValueChange, globalStepValues, displayedStep, maxStep, versions}) => {
  const initVersionChoices = () => {
    return selectVersions(versions, changeType)
  }
  const initSelectedVersionNumber = () => {
    return selectVersions(versions, changeType)[0].versionNumber
  }
  const initNextVersionNumber = () => {
    return getNextVersion(selectVersions(versions, changeType)[0].versionNumber, changeType)
  }

  const [versionsChoices] = useState(initVersionChoices());
  const [selectedVersionNumber, setSelected] = useState(initSelectedVersionNumber());
  const [nextVersionNumber, setNextVersion] = useState(initNextVersionNumber());

  const handleChangeSelectedVersion = (e) => {
    setSelected(e.target.value);
    setNextVersion(getNextVersion(e.target.value, globalStepValues[0].versionType));
  };

  const handleNextClick = () => {
    onValueChange({ nextVersion: nextVersionNumber, selected: selectedVersionNumber });
    onNext();
  };

  const handlePreviousClick = () => {
    setSelected(null);
    onValueChange({});
    onPrevious();
  };

  const params = {
    handleNextClick,
    handlePreviousClick,
    handleChangeSelectedVersion,
    maxStep,
    displayedStep,
    versionsChoices,
    nextVersion: nextVersionNumber,
    selected: selectedVersionNumber,
  };

  return <View {...params} />
}