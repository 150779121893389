import { Button } from "../../../../Button/Button";
import { useTranslation } from "react-i18next";
import * as sc from "../../style";
import { VersionUpload } from "../../../../VersionUpload";
export default function View({ handleNextClick, handlePreviousClick, displayedStep, maxStep, handleChangeFile, isLoading, file}) {
  const { t } = useTranslation();
  return (
    <sc.Container>
      <sc.Title>{t("create_new_version_btn")}</sc.Title>
      <sc.SubTitle>{t("step_of", { num: displayedStep, max: maxStep })}</sc.SubTitle>
      <sc.DownloadContainer>
        <VersionUpload
          handleChange={handleChangeFile}
          file={file}
        />
        <sc.Spacer height="20px" />
        <sc.H2>{t("version_change_upload_new_version")}</sc.H2>
        <sc.SubTitle>{t("version_change_upload_new_version_subtitle")}</sc.SubTitle>
      </sc.DownloadContainer>
      <sc.BtnContainer>
        <sc.Previous
          onClick={handlePreviousClick}
        >
          {t("previous")}
        </sc.Previous>
        <Button
          label={isLoading ? <sc.Spinner /> : "Upload new version"}
          onClick={handleNextClick}
          small={true}
          disabled={!file}
        />
      </sc.BtnContainer>
    </sc.Container>
  )
}