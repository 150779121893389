import styled from "styled-components";
import { colors } from "../../helpers/style/constants";
import { spaces } from '../../helpers/style/constants';
import { width } from '../../helpers/style/constants';

export const Input = styled.input`
  background-color: ${colors.input_bg};
  border-radius: 6px;
  border: ${p => p.error ? "1px solid #707070" : `1px solid ${colors.light_grey}`};
  color: ${colors.dark_grey};
  font-size: 15px;
  font-weight: 400;
  outline: none;
  height: 48px;
  padding: 0 62px 0 10px;
  margin-right: ${p => p.type === "password"
      && "10px"
  };
  min-width: ${p => p.icon
      ? "306px"
      : "340px"
  };
  transition: .2s ease-out;

  &:focus {
    border: ${p => p.error ? "1px solid #707070" : `1px solid ${colors.input_border_focus}`};
    box-shadow: 0 0 0 2px #f0f7ff;
  }

  &::placeholder {
    color: ${colors.input_placeholder};
  }
`;

export const Password = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const ShowPassword = styled.button`
  background-color: transparent;
  border: none;
  height: 35px;
  width: 35px;
  outline: none;
  cursor: pointer;
  margin: ${p => p.password
    ? "4px 9px 0 -44px"
    : "4px 9px 0 -54px"
  };
`;

export const WithIcon = styled.div`
  display: flex;
  align-items: center;
`;

export const SearchApp = styled.div`
  margin: 0 ${spaces.space_lg} 0 0;
  width: calc(100% - ${width.width_btn});
  position: relative;
`;

export const Icon = styled.div`
  position: absolute;
  margin-left: 18px;
`;

export const IconSearch = styled.div`
  position: absolute;
  right: 15px;
  top: 16px;
`;
