import styled from "styled-components";
import { colors } from "../../../helpers/style/constants";

export const ListContainer = styled.ul`
  list-style-type: none;
  padding: 0;
`;

export const ListItem = styled.li`
  border-left: 1px solid ${colors.list_border};
  border-right: 1px solid ${colors.list_border};
  transition: 0.2s ease-out;
  padding: 10px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  &:first-child{
    border-top: 1px solid ${colors.list_border};
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &:last-child{
    border-bottom: 1px solid ${colors.list_border};
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  &:not(first-child), &:not(last-child){
    border-bottom: 1px solid ${colors.list_border};
  }
  &:hover{
    background-color: ${colors.very_light_purple};
    cursor: pointer;
  }
`;

export const InfoContainer = styled.div`
  margin-left: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
`;

export const IconAction = styled.div`
  background-color: ${p => p.type === "workflow"
    ? colors.workflow_background
    : p.type === "webService"
      ? colors.web_service_background
      : "red"};
  border: ${p => p.type === "workflow"
    ? `2px solid ${colors.workflow_background}`
    : p.type === "webService"
      ? `2px solid ${colors.web_service_background}`
      : "red"};
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;

  & > svg {
    color: ${p => p.type === "workflow"
    ? colors.workflow_icon
    : p.type === "webService"
      ? colors.web_service_icon
      : "green"
  };
  }
`;

export const IconEvent = styled.div`
  background-color: ${p => p.type === "webhook"
    ? colors.workflow_background
    : colors.web_service_background};
  border: ${p => p.type === "webhook"
    ? `2px solid ${colors.workflow_background}`
    : `2px solid ${colors.web_service_background}`};
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;

  & > svg {
    color: ${p => p.type === "webhook" ? colors.workflow_icon : colors.web_service_icon};
  }
`;

export const IconContext = styled.div`
  background-color: ${p => p.isCrypted
    ? colors.web_service_background
    : colors.workflow_background};
  border: ${p => p.isCrypted
    ? `2px solid ${colors.web_service_background}`
    : `2px solid ${colors.workflow_background}`};
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;

  & > svg {
    color: ${p => p.isCrypted ? colors.web_service_icon : colors.workflow_icon};
  }
`;

export const DoubleIconContainer = styled.div`
  position: relative;
  width: 56px;
  height: 56px;
`;

export const DoubleIconTopAction = styled.div`
  background-color: ${p => p.type === "workflow"
    ? colors.workflow_background
    : colors.web_service_background};
  border: ${p => p.type === "workflow"
    ? `2px solid ${colors.workflow_background}`
    : `2px solid ${colors.web_service_background}`};
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;

  & > svg {
    color: ${p => p.type === "workflow" ? colors.workflow_icon : colors.web_service_icon};
    width: 17px;
  }
`;

export const DoubleIconTopEvent = styled.div`
  background-color: ${p => p.type === "webhook"
    ? colors.workflow_background
    : colors.web_service_background};
  border: ${p => p.type === "webhook"
    ? `2px solid ${colors.workflow_background}`
    : `2px solid ${colors.web_service_background}`};
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;

  & > svg {
    color: ${p => p.type === "webhook" ? colors.workflow_icon : colors.web_service_icon};
    width: 17px;
  }
`;

export const DoubleIconBottomAction = styled.div`
  background-color: ${p => p.type === "workflow"
      ? colors.workflow_background
      : colors.web_service_background};
  border: ${p => p.type === "workflow"
      ? `2px solid ${colors.workflow_background}`
      : `2px solid ${colors.web_service_background}`};
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0;
  bottom: 0;

  & > svg {
    color: ${p => p.type === "workflow" ? colors.workflow_icon : colors.web_service_icon};
    width: 17px;
  }
`;

export const DoubleIconBottomEvent = styled.div`
  background-color: ${p => p.type === "webhook"
      ? colors.workflow_background
      : colors.web_service_background};
  border: ${p => p.type === "webhook"
      ? `2px solid ${colors.workflow_background}`
      : `2px solid ${colors.web_service_background}`};
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0;
  bottom: 0;

  & > svg {
    color: ${p => p.type === "webhook" ? colors.workflow_icon : colors.web_service_icon};
    width: 17px;
  }
`;

export const Name = styled.div`
  color: ${colors.text};
  font-size: 16px;
  font-weight: 500;
  margin-bottom: -6px;
`;

export const Description = styled.span`
  color: ${colors.list_actions_count};
  font-size: 12px;
  font-weight: 400;
`;
