import * as service from "./service";

import { useContext, useState } from "react";

import { KeycloakContext } from "../../../../../auth/AuthWrapper";
import View from "./View";
import { onError } from "../../../../../helpers/onError";
import { useQueryClient } from "react-query";

export const Step1 = ({values, onValueChange, onNext, globalStepValues, ...props}) => {
    const queryClient = useQueryClient()
    const [state] = useContext(KeycloakContext);
    const [name, setName] = useState(values?.name || "");
    const [isLoading, setIsLoading] = useState(false);
    const [inputError, setInputError] = useState("");
    const [isGenerating, setIsGenerating] = useState(false);

    // NOTE Change the name value
    const handleChangeName = event => {
        if (!isLoading) {
            setInputError("");
            const value = event.target.value;
            const maxLength = 32;

            if (value.length <= maxLength) {
                setName(value);
            }
        }
    }

    const handleCreateApp = async () => {
        try {
            setIsLoading(true);
            const token = state.keycloak.token;
            const res = await service.checkAppNameAvailability(name, token);
            // If app name already exists, display error below input field
            res.status === 200 && setInputError("The application name already exists");
            return;
        } catch (error) {
            const status = error.response.status;
            if (status === 404) {
                // If app name is available, continue to the next step
                onValueChange({name});
                onNext();
            } else if (status === 500) {
                onError(error, state);
            }
        } finally {
            setIsLoading(false);
        }

        setIsGenerating(true);
        const token = state.keycloak.token;
        const payload = {
            name: name.trim()
        };
        await service.createApplication(payload, token)
            .then(
                (response) => {
                    queryClient.invalidateQueries("applications");
                    const id = response.data.id;
                    onValueChange({"id": id});
                },
                () => {
                    throw Error('fail');
                }
            ).finally(
                () => {
                    onNext();
                }
            );
    }

    /**
     * NOTE Rendering component
     */

    const params = {
        name,
        handleChangeName,
        inputError,
        isLoading,
        handleCreateApp,
        isGenerating,
    };
    return <View {...params} />
}
