import NewCreateModal from "../Modal/NewCreateModal";
import * as sc from "./style";

export function EmptyState({ icon, title, message, label }) {

  return (
    <>
      <sc.Wrapper>
        <sc.Spacer height="120px" />
        <sc.Icon>
          <sc.Span>
            {icon}
          </sc.Span>
        </sc.Icon>
        <sc.Spacer height="30px" />
        <sc.H2>
          {title}
        </sc.H2>
        <sc.Spacer height="20px" />
        <sc.Text>
          {message}
        </sc.Text>
        <sc.Spacer height="20px" />
      </sc.Wrapper>
    </>
  )
}