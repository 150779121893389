export const getStoreUrl = (url) => {
const posHttp = url.indexOf("://");
if (posHttp !== -1) url = url.substring(posHttp+3);

const posLocalhost = url.indexOf("localhost");
const isLocal = posLocalhost === 0;

let tenant, environment, pathArray;

if (isLocal) {
    tenant = "product";
    environment = "staging";
} else {
    pathArray = url.split(".");
    tenant = pathArray[0];
    environment = pathArray[1];
}

const posAnchor = tenant.indexOf("#");
if (posAnchor !== -1) tenant = tenant.substring(0, posAnchor);

if (isLocal || pathArray.length >= 5) {
    return `https://${tenant}.${environment}.store.marjory.io/`;
}

return `https://${tenant}.store.marjory.io/`;
}
