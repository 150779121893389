import { useContext, useEffect, useRef, useState } from "react";
import * as sc from "./style";
import * as Icon from "react-feather";
import { useTranslation } from "react-i18next";
import { KeycloakContext } from "../../auth/AuthWrapper";
import { Swap } from "../Swap/Swap";
import { Logo as Avatar } from "../Logo/Logo";
import Button from "../Button/Button";
import MarjoryLogo from "../../components/MarjoryLogo";
import LanguageModal from "../Modal/LanguageModal";
import { useOutsideClick } from "../../hooks/useOutsideClick";

export default function Header({ userData }) {
  const { t } = useTranslation();

  const [state] = useContext(KeycloakContext);
  const [openLanguageModal, setOpenLanguageModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const onOpenLanguageModal = () => setOpenLanguageModal(true);
  const onCloseLanguageModal = () => setOpenLanguageModal(false);

  const handleClick = () => setIsOpen(!isOpen);

  const buttonRef = useRef(null);
  const buttonClickedOutside = useOutsideClick(buttonRef);

  useEffect(() => {
    if (isOpen && buttonClickedOutside) {
      handleClick();
    }
  }, [buttonClickedOutside]);

  return (
    <>
      <sc.Wrapper>
        <sc.HeaderContainer>
          <sc.LeftSide>
            <MarjoryLogo />
            <sc.SwitchPart>
              <Swap />
            </sc.SwitchPart>
          </sc.LeftSide>
          <sc.UserContainer>
            <sc.Username>
              {userData.username}
            </sc.Username>
            <Avatar
              name={userData.username}
              size="small"
            />
            <Button
              buttonType="borderlessIcon"
              icon={isOpen ? "ChevronUp" : "ChevronDown"}
              primary={false}
              onClick={handleClick}
            />
            <sc.DropdownMenu ref={buttonRef} visible={isOpen}>
              {/* MR-1433
              <sc.LanguageItem onClick={() => onOpenLanguageModal()}>
                <sc.IconBox>
                  <sc.IconTagBox>
                    <Icon.Globe />
                  </sc.IconTagBox>
                </sc.IconBox>
                <sc.Label>{t("language")}</sc.Label>
              </sc.LanguageItem>
              */}
              <sc.Logout onClick={() => _logout(state)}>
                <sc.IconBox>
                  <sc.IconTagBox rotate="true">
                    <Icon.LogOut />
                  </sc.IconTagBox>
                </sc.IconBox>
                <sc.Label>{t("log_out")}</sc.Label>
              </sc.Logout>
            </sc.DropdownMenu>
          </sc.UserContainer>
        </sc.HeaderContainer>
      </sc.Wrapper >
      <LanguageModal
        open={openLanguageModal}
        onCloseModal={onCloseLanguageModal}
      />
    </>
  )
}

const _logout = ({ keycloak }) => {
  keycloak.logout();
};
