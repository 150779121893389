import { useState } from "react";
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { isMobile } from "react-device-detect";
import SnackbarProvider from "react-simple-snackbar";
import { GlobalStyle } from "./helpers/GlobalStyle";
import AuthWrapper from "./auth/AuthWrapper";
import Nav from "./components/Nav/index";
import Applications from "./pages/Applications/index";
import Application from "./pages/Application/index";
// import Submissions from "./pages/Submissions/index";
// import Submitted from "./pages/Submitted/index";
import ErrorPage from "./pages/ErrorPage/index";
import NotFound from "./pages/NotFound/index";
import Deployments from "./pages/Deployments";
import Unavailable from "./components/Unavailable";
import { ToastContainer } from 'react-toastify';
import { colors } from "../src/helpers/style/constants";

export default function App() {
  const [state, setState] = useState(null);
  const setBaseName = (baseName) => { setState(baseName) };
  isMobile && <Unavailable />

  return (
    <SnackbarProvider>
      <AuthWrapper baseName={setBaseName}>
        <BrowserRouter basename={state}>
          <Nav />
          <Switch>
            <Route exact path="/"><Redirect to="/apps" component={Applications} /></Route>
            <Route exact path="/apps" component={Applications} />
            <Route exact path="/apps/:applicationName" component={Application} />
            <Route exact path="/apps/:applicationName/v/:version" component={Application} />
            <Route exact path="/deployments" component={Deployments} />
            {/* <Route exact path="/submissions" component={Submissions} /> */}
            {/* <Route exact path="/submissions/:applicationName" component={Submitted} /> */}
            <Route exact path="/error/:statusCode" component={ErrorPage} />
            <Route exact path="/404" component={NotFound} />
            <Route component={NotFound} />
          </Switch>
        </BrowserRouter>
      </AuthWrapper>
      <ToastContainer
        toastStyle={{
          backgroundColor: colors.deployment_tag_failed_bg,
          color: colors.deployment_tag_failed_label,
          borderRadius: "8px"
        }}
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <GlobalStyle />
    </SnackbarProvider>
  );
}