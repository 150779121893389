import * as sc from "./style";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";

export const DeleteAppModal = ({ open, onCloseModal, application, defaultVersion, handleChangeDeleteValue, deleteValue, disabled, handleDeleteDraft, handleDeleteVersion, isDeleting }) => {

  const version = _getVersionNumber(application.applicationAllVersions, defaultVersion);

  if (application.currentVersionVisibility === "private") {
    return (
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        classNames={{
          overlay: "custom-overlay",
          modal: "custom-modal"
        }}
      >
        <sc.Title>Delete {_capitalize(application.applicationName)} version {version}</sc.Title>
        <sc.Alert>You are about to delete private version {version}, this could impact existing technical workflows in your Marjory automation platform. Are you sure you want to perform this action?</sc.Alert>
        <sc.Spacer height="1px" />
        <sc.H3>Please type the application name to delete the version:</sc.H3>
        <sc.Input
          placeholder="Enter the application name"
          onChange={handleChangeDeleteValue}
          value={deleteValue}
        />
        <sc.Spacer height="5px" />
        <sc.BtnContainer>
          <sc.Button
            disabled={disabled}
            onClick={handleDeleteVersion}
          >
            {isDeleting ? <sc.Spinner /> : "Delete"}
          </sc.Button>
        </sc.BtnContainer>
        <sc.Spacer height="20px" />
      </Modal>
    )
  }

  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      center
      classNames={{
        overlay: "custom-overlay",
        modal: "custom-modal"
      }}
    >
      <sc.Title>Delete {_capitalize(application.applicationName)} application</sc.Title>
      <sc.Alert>You are about to delete your application, you will not be able to recover it, are you sure you want to perform this action?</sc.Alert>
      <sc.Spacer height="1px" />
      <sc.H3>Please type the application name to delete the version:</sc.H3>
      <sc.Input
        placeholder="Enter the application name"
        onChange={handleChangeDeleteValue}
        value={deleteValue}
      />
      <sc.Spacer height="5px" />
      <sc.BtnContainer>
        <sc.Button
          disabled={disabled}
          onClick={handleDeleteDraft}
        >
          {isDeleting ? <sc.Spinner /> : "Delete"}
        </sc.Button>
      </sc.BtnContainer>
      <sc.Spacer height="20px" />
    </Modal>
  )
}

// Capitalize first letter of each word
const _capitalize = string => {
  return string.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
}

// Return version number from version ID
const _getVersionNumber = (versions, currentVersionId) => {
  const version = versions.find(item => item.versionId === currentVersionId);
  return version?.versionNumber || null;
}