import axios from "axios";
import { getConfig } from "../../helpers/getConfig";
import * as mapper from "./mapper";

const FACTORY_API_URL = process.env.REACT_APP_FACTORY_API_URL;

/**
 * List all deployments of a specific user
 * @param  {String} token The keycloak token
 * @return {Array}        The list of deployments
 */
export const listDeployments = async (token) => {
  try {
    const config = getConfig(token);
    const deploymentsList = await axios.get(`${FACTORY_API_URL}/deployments`, config);
    // Replace id with deployment ID to avoid mismatch when merging deployments and applications
    const deployments = mapper.renameIdWithdeploymentId(deploymentsList); 
    // Récupérer la liste des applications ID
    const idList = deploymentsList.data.map(item => item.application);
    const uniqueIdList = [...new Set(idList)];

    // Pour chaque id appeler la route GET /applications
    const results = await Promise.all(uniqueIdList.map(item => {
      return axios.get(`${FACTORY_API_URL}/applications/${item}`, config);
    }));

    const mergedResults = mergeDiffs(deployments, results);

    return mapper.deployments(mergedResults);
  } catch(err) {
    console.error(err);
    throw err;
  }
}

export const retryDeployment = async (token, deploymentId, zipFile) => {
  try {
    const config = getConfig(token);
    const params = new FormData();
    params.append("zip", zipFile);
    params.append("changelog", "");
    params.append("comment", "");
    
    return await axios.patch(`${FACTORY_API_URL}/deployments/${deploymentId}`, params, config);
  } catch (err) {
    throw err;
  }
}

const mergeDiffs = (arr1, arr2) => {
  const array2 = arr2.map(item => item.data);

  // Remove application createdAt & updatedAt to avoid confusion with deployment createdAt & updatedAt
  array2.map(item => {
    delete item.updatedAt;
    delete item.createdAt;
    delete item.status;
  });

  return arr1.map(a1 => ({ ...a1, ...array2.find(a2 => a2.id === a1.application) }));
}