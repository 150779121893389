import styled from "styled-components";
import { colors } from "../../helpers/style/constants";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 88px;
  justify-content: space-between;
`;

export const DropdownButton = styled.button`
  width: 44px;
  height: 44px;
  border-radius: 44px;
  border: none;
  background-color: ${p => p.isOpen ? "#f8f8f8": "#fff"};
  
  &:hover {
    background-color: #f8f8f8;
    cursor: pointer;
  }
`;

export const DropdownIcon = styled.img`
  height: 22px;
  display: flex;
  margin: 0 auto;
`;

export const DropdownMenu = styled.div`
  box-shadow: 0 1px 6px #00000029;
  background-color: #fff;
  border-radius: 5px;
  position: absolute;
  top: 60px;
  width: 305px;
  opacity: ${props => (props.visible ? "1" : "0")};
  height: ${props => (props.visible ? "auto" : "0")};
  padding: ${props => (props.visible ? "0px" : "0px")};
  transition: all 0.2s ease-out;
  overflow: hidden;
`;

export const Menu = styled.div`
  padding: 4px 0;
`;

export const MenuTitle = styled.span`
  padding: 9px 1rem;
  font-size: 16px;
  font-weight: bold;
  color: #2e3a72;
  display: block;
`;

export const Divider = styled.hr`
  border: none;
  border-top: 1px solid #d6d6d6;
  margin: 0.25rem 10px;
`;

export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

export const ListItemLink = styled.a`
  align-items: center;
  display: flex;
  transition: .1s linear;
  text-decoration: none;
  font-weight: 500;

  &:hover {
    background-color: ${colors.very_very_light_grey};
  }
`;

export const LinkIcon = styled.img`
  height: 40px;
`;

export const LinkTitle = styled.span`
  margin-left: 10px;
  font-size: 15px;
  color: black;
  transition: .1s linear;
`;

export const LinkBaseline = styled.span`
  margin-left: 10px;
  color: ${colors.secondary_text};
  font-size: 12px;
  font-weight: 400;
`;

export const LinkContainer = styled.div`
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
`;

export const PortalIcon = styled.img`
  width: 35px;
  height: 40px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
