import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const Msg = ({ message }) =>{
  const { t } = useTranslation();
  return  (
  <div>
    {t(message)}
  </div>
)}

export const onError = (error, state) => {
  console.log(error);
  const status = error?.response?.status;
  if(status === 401) {
    return state.keycloak.logout();
  }

  toast.error(<Msg message={error.message ?? "An error occured"}/>, {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  return true;
}
