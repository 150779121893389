
export const selectVersions = (versions, changeType) => {
  const versionsArrays = generateVersionsArray(versions ?? [], changeType ?? "major");
  if (changeType === "minor") {
    return getMinorVersions(versionsArrays);
  }
  if (changeType === "fix") {
    return getFixVersions(versionsArrays);
  }
 
  return getMajorVersions(versionsArrays);
}

export const generateVersionsArray = (versions) => {
    let convertedVersions = [];
    if (versions.length === 0) {
        return [[0,0,0]];
    }
    versions.forEach((version) => {
        const convertedVersion = version.versionNumber.split(".").map(Number);
        convertedVersions.push(convertedVersion);
    })
    // eslint-disable-next-line array-callback-return
    const convertedVersionsSorted = convertedVersions.sort((a, b) => {
        if (b[0] !== a[0]){
            return b[0] - a[0]
        } else if (b[1] !== a[1]) {
            return b[1] - a[1]
        } else if (b[2] !== a[2]) {
            return b[2] - a[2]
        }
    })
    return convertedVersionsSorted;
}

export const getMajorVersions = (versions) => {
    return [{versionNumber: `${versions[0][0]}.y.z`}];
}

export const getMinorVersions = (versions) => {
    let lastValue;
    let versionsList = [];
    versions.forEach((version) => {
        if (versionsList.length === 0 || lastValue[0] !== version[0]){
            lastValue = version;
            const value = { versionNumber:`${version[0]}.${version[1]}.z` }
            versionsList.push(value);
        }
    })
    return versionsList;
}

export const getFixVersions = (versions) => {
    let lastValue;
    let versionsList = [];
    versions.forEach((version) => {
        if (versionsList.length === 0 || lastValue[0] !== version[0] || lastValue[1] !== version[1]){        
            lastValue = version;
            const value = { versionNumber: `${version[0]}.${version[1]}.${version[2]}` }
            versionsList.push(value);
        }
    })
    return versionsList;
}

export const getNextVersion = (versionNumber, versionType) => {
    const splitted = versionNumber.split(".");
    if (versionType === "minor") {
      return  `${splitted[0]}.${(+splitted[1]) + 1}.0`
    }

    if (versionType === "fix") {
      return  `${splitted[0]}.${splitted[1]}.${(+splitted[2]) + 1}`
    }

    return  `${(+splitted[0]) + 1}.0.0`
  } 