import i18n from "i18next";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/translation-en.json";
import translationDE from "./locales/translation-de.json";
import translationFR from "./locales/translation-fr.json";
import translationES from "./locales/translation-es.json";

const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  es: {
    translation: translationES,
  },
  de: {
    translation: translationDE,
  },
};

i18n
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    languages: ["en", "fr", "es", "de"],
    fallbackLng: "en",
    lng: "en", // MR-1433 fixed lang
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
      // wait: false,
    },
    backend: {
      backends: [LocalStorageBackend, HttpBackend],
      backendOptions: [
        {
          expirationTime: 365 * 24 * 60 * 60 * 1000,
        },
        {
          loadPath: "./locales/{{lng}}/translation.json",
        },
      ],
    },
  });

export default i18n;
