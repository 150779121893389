import styled from "styled-components";
import { colors } from "../../helpers/style/constants";

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Container = styled.div`
  width: 320px;
`;

export const Info = styled.div`
  font-size: 16px;
  color: ${colors.dark_grey};
  white-space: nowrap;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LeftContainer = styled.div`
  align-items: center;
  display: flex;
  & > svg {
    width: 20px;
    stroke: ${colors.marjory_blue};
  }
`;

export const Text = styled.span`
  margin: 18px 15px;
  font-size: 15px;
  font-weight: 500;
`;

export const Divider = styled.div`
  border-top: 0.5px solid ${colors.light_grey};
`;

export const Content = styled.span`
  color: ${colors.text};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 15px;
`;

export const Status = styled.span`
  align-items: center;
  /* background-color: ${p => p.status === "public" ? "#ebf4ff" : "#fff1eb"}; */
  background-color: ${p => p.status === "private"
        ? "#fff1eb"
        : p.status === "public"
          ? "#f0efff"
          : "#eef2f8"
  };
  border-radius: 5px;
  /* color: ${p => p.status === "public" ? "#18187A" : "#d63333"}; */
  color: ${p => p.status === "private"
    ? "#d63333"
    : p.status === "public"
      ? "#18187A"
      : "#79869e"
  };
  display: flex;
  font-size: 11px;
  font-weight: bold;
  height: 24px;
  justify-content: center;
  padding: 4px 18px;
  text-transform: uppercase;
`;

export const Link = styled.a`
  display: flex;
  align-items: center;
  margin: 18px 15px;
  color: ${p => p.red ? "red" : "#18187A"};
  text-decoration: none;
  font-size: 15px;

  &:hover {
    text-decoration: underline;
  }
  
  & > svg {
    margin-left: 5px;
    width: 16px;
  }
`;

export const Spacer = styled.div`
  height: ${p => p.height ? `${p.height}` : ""};
`;
