import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 430px;
  height: 80vh;
  margin: 0 auto;
`;

export const Icon = styled.div`
  background-color: #f7f6f6;
  width: 120px;
  height: 120px;
  border-radius: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Span = styled.span`
  font-size: 72px;
`;

export const H2 = styled.h2`
  font-size: 28px;
  font-weight: 900;
  margin: 0;
`;

export const Text = styled.p`
  color: #929292;
  font-weight: 700;
  text-align: center;
  font-size: 20px;
  margin: 0;
`;

export const Spacer = styled.div`
  height: ${p => p.height ? `${p.height}` : ""};
`;
