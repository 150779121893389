import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Step = styled.div`
  border: ${p => p.status === "Complete"
    ? "2px solid #39CB90"
    : p.status === "Failed"
      ? "2px solid #ff2121"
      : p.status === "Running"
        ? "2px solid #027aff"
        : "2px solid #e4e8f0"
  };
  width: 18px;
  height: 18px;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    width: 14px;
    height: 14px;
    color: ${p => p.status === "Complete"
      ? "#39CB90"
      : p.status === "Failed"
        ? "#ff2121"
        : p.status === "Running"
          ? "#027aff"
          : "#e4e8f0"
    };
  }
`;

export const Pipe = styled.div`
  background-color: #e4e8f0;
  width: 15px;
  height: 2px;
`;

export const Spinner = styled.div`
  padding: 9px;
  animation: load8 1.2s infinite linear;
  transform: translateZ(0);
  border: 2px solid #e4e8f0;
  border-left: 2px solid #027aff;
  border-radius: 22px;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;