import styled from "styled-components";
import { colors } from "../../helpers/style/constants";

export const Select = styled.select`
  border: 1px solid ${colors.new_input_border};
  border-radius: 6px;
  color: ${colors.text};
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  height: 48px;
  margin: 0 0 18px;
  outline: none;
  padding: 0 10px;
  transition: .2s ease-out;
  width: 100%;
  background: white;

  &:focus {
    border: 1px solid ${colors.new_input_border_focus};
    box-shadow: 0 0 0 3px #f0f7ff;
  }
`;
