import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { KeycloakContext } from "../../auth/AuthWrapper";
import Header from "../Header/index";
import * as Icon from "react-feather";
import * as sc from "./style";


const DOCUMENTATION_URL = `https://${ process.env.REACT_APP_STAGE !== 'prod.' && process.env.REACT_APP_STAGE !== 'undefined' ?  process.env.REACT_APP_STAGE : ''}docs.marjory.io/`;

export default function Nav() {
  const { t } = useTranslation();
  const history = useHistory();

  const [state] = useContext(KeycloakContext);

  useEffect(() => {
    !state.userRoles && history.push("/error/403");
  }, [state.userRoles, history]);

  return (
    <>
      <Header userData={state} />
      <sc.Nav>
        {_shouldSee("services-read", state) && (
          <sc.NavItem to="/apps">
            <sc.IconBox>
              <sc.IconTagBox>
                <Icon.Box />
              </sc.IconTagBox>
            </sc.IconBox>
            <sc.NavLabel>{t("applications")}</sc.NavLabel>
          </sc.NavItem>
        )}
        {_shouldSee("services-read", state) && (
          <sc.NavItem to="/deployments">
            <sc.IconBox>
              <sc.IconTagBox>
                <Icon.Zap />
              </sc.IconTagBox>
            </sc.IconBox>
            <sc.NavLabel>{t("deployments")}</sc.NavLabel>
          </sc.NavItem>
        )}
        {/* {_shouldSee("technical-partner-onboarding", state) && (
          <>
            <sc.NavItem to="/submissions">
              <sc.IconBox>
                <sc.IconTagBox>
                  <Icon.Inbox />
                </sc.IconTagBox>
              </sc.IconBox>
              <sc.NavLabel>{t("submissions")}</sc.NavLabel>
            </sc.NavItem>
          </>
        )} */}
        <sc.Spacer />
        <sc.ExternalNavItem target="_blank" href={DOCUMENTATION_URL}>
          <sc.IconBox>
            <sc.IconTagBox>
              <Icon.Info />
            </sc.IconTagBox>
          </sc.IconBox>
          <sc.NavLabel>{t("guide")}</sc.NavLabel>
        </sc.ExternalNavItem>
        <sc.ExternalNavItem target="_blank" href="mailto:partner@marjory.io">
          <sc.IconBox>
            <sc.IconTagBox>
              <Icon.AtSign />
            </sc.IconTagBox>
          </sc.IconBox>
          <sc.NavLabel>{t("contact")}</sc.NavLabel>
        </sc.ExternalNavItem>
      </sc.Nav>
    </>
  );
}

// NOTE Determine if the user can see the component based on their roles
const _shouldSee = (roleName, { userRoles }) => {
  if (!userRoles) return false;
  return userRoles.find((role) => role === roleName);
};
