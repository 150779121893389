import axios from "axios";
import { getConfig } from "../../../../../helpers/getConfig";
import slugify from 'slugify';

const API_URL = process.env.REACT_APP_FACTORY_API_URL;

/**
 * Check if app name already exists
 * @param  {name}   value The desired app name
 * @param  {String} token The keycloak token
 * @return {Object}       The result of the query
 */
export const checkAppNameAvailability = async (name, token) => {
  try {
    const config = getConfig(token);
    const slugifiedName = slugify(name.toLowerCase());
    return await axios.get(`${API_URL}/applications/${slugifiedName}`, config);
  } catch(err) {
    console.error(err);
    throw err;
  }
}

/**
 * Create a new application
 */
 export const createApplication = async (payload, token) => {
  try {
    const config = getConfig(token);
    return await axios.post(`${API_URL}/applications`, payload, config);
  } catch(err) {
    console.error(err);
    throw err;
  }
}

