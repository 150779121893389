export const deployments = data => {
  const arrayOfResults = [];
  data.forEach(item => {
    const deployment = {
      id: item?.deploymentId,
      name: item?.name,
      slug: item?.slug,
      icon: item?.icon,
      version: item?.version,
      updatedAt: (item?.updatedAt || item?.createdAt) / 1000,
      duration: item?.duration,
      step: item?.step,
      status: _setStatus(item?.status),
      errors: _isEmptyObject(item?.errors) ? null : item?.errors.flat()
    };
    arrayOfResults.push(deployment);
  });
  
  return _sortArrayOfObject(arrayOfResults);
}

export const renameIdWithdeploymentId = deployments => {
  const arrayOfResults = [];
  deployments.data.forEach(item => {
    const deployment = {
      deploymentId: item?.id,
      ...item
    };
    arrayOfResults.push(deployment);
  });

  return arrayOfResults;
}

const _setStatus = status => {
  switch (status) {
    case "finished":
      return "Complete";
    case "failed":
      return "Failed";
    default:
      return "Running";
  }
}

const _sortArrayOfObject = array => {
  // return array.sort((a,b) => (a.updatedAt || a.startedAt < b.updatedAt || b.startedAt) ? 1 : ((b.updatedAt || b.startedAt < a.updatedAt || a.startedAt) ? -1 : 0))
  return array.sort((a,b) => (a.updatedAt < b.updatedAt) ? 1 : ((b.updatedAt < a.updatedAt) ? -1 : 0))
}

const _isEmptyObject = inputObject => {
  return Object.keys(inputObject).length === 0;
};