import styled from "styled-components";

export const Wrapper = styled.div`
  padding-top: 100px;
  z-index: 1000;
  height: 100vh;
  width: 100%;
  position: absolute;
  background-color: #fff;
  text-align: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;