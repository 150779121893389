import React from "react";
import PropTypes from "prop-types";
import * as Icon from "react-feather";
import * as sc from "./Button.style";

export function Button ({ primary, small, label, block, icon, buttonType, type, onClick, isLoading, id, disabled, menu, ...props }) {
  const hasIcon = () => {
    const IconTag = Icon[icon];
    return icon && (
      <sc.Icon buttonType={buttonType}>
        <IconTag />
      </sc.Icon>
    );
  }

  if (buttonType) {
    if (buttonType === "squaredIcon") {
      return (
        <sc.Button
          type="button"
          buttonType={buttonType}
          primary={primary}
          {...props}
        >
          {hasIcon()}
        </sc.Button>
      )
    } else if (buttonType === "borderlessIcon") {
      return (
        <sc.BorderlessButton
          type="button"
          buttonType={buttonType}
          primary={primary}
          onClick={onClick}
          {...props}
        >
          {hasIcon()}
        </sc.BorderlessButton>
      )
    }
  }

  return (
    <sc.Button
      type={type || "button"}
      primary={primary}
      block={block}
      small={small}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {isLoading && isLoading === id
        ? <sc.Spinner />
        : <>{hasIcon()}{label}</>
      }
    </sc.Button>
  );
}

Button.propTypes = {
  primary: PropTypes.bool,
  label: PropTypes.string.isRequired,
  block: PropTypes.bool,
  small: PropTypes.bool
};

Button.defaultProps = {
  primary: true,
  label: "Create a module",
  block: false,
  small: false
};


export default Button;