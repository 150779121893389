import { useState } from "react";
import View from "./View";

export const Step3 = ({ onNext, onPrevious, onValueChange, globalStepValues, displayedStep, maxStep, values }) => {
  
  // const [visibility, setVisibility] = useState(values?.visibility || "private");
  const [visibility, setVisibility] = useState("private");
  const [commit, setCommit] = useState(values?.commit || "");
  const [changelog, setChangelog] = useState(values?.changelog || "");

  const handleChangeVisibility=(e) => {
    setVisibility(e.target.value);
  };
  
  const handleChangeCommit=(e) => {
    setCommit(e.target.value);
  };

  const handleChangelog=(e) => {
    setChangelog(e.target.value);
  };

  const handleNextClick = () => {
    onValueChange({commit, changelog, visibility});
    onNext();
  };

  const handlePreviousClick = () => {
    onPrevious();
  };

  const isFormValid = () => {
    return commit && changelog;
  };

  const params = { 
    handleNextClick,
    handlePreviousClick,
    maxStep,
    displayedStep,
    handleChangelog,
    handleChangeCommit,
    handleChangeVisibility,
    visibility,
    isFormValid: isFormValid(),
  };

  return <View {...params} />
}