import { Button } from "../../../../Button/Button";
import { useTranslation } from "react-i18next";
import { AppTextarea } from "../../../../AppTextarea";
import * as sc from "../../style";
import { VisibilityRadio } from "../../../../AppRadios/VisibilityRadio";
import { Input } from "../../../../Input/Input";

export default function View({ handleNextClick, handlePreviousClick, displayedStep, maxStep, handleChangelog, handleChangeCommit, handleChangeVisibility, visibility, isFormValid}) {
  const { t } = useTranslation();

  const options = [
    {
      icon: 'Shield',
      value: 'PRIVATE',
      label: 'private'
    },
    {
      icon: 'Users',
      value: 'GROUP',
      label: 'group'
    },
    {
      icon: 'Eye',
      value: 'PUBLIC',
      label: 'public'
    },
  ];

  return (
    <sc.Container>
      <sc.Title>{t("create_new_version_btn")}</sc.Title>
      <sc.SubTitle>{t("step_of", { num: displayedStep, max: maxStep })}</sc.SubTitle>
      {/* Deactivate visibility choice, version must be currently private */}
      {/* <sc.H2>{t("visibility")}</sc.H2>
      TODO Create Version Tag Picker component ?
      <sc.TagWrapper
        style={{width:"350px"}}
      >
      <VisibilityRadio
        options={options}
        handleChange={handleChangeVisibility}
        selected={visibility}
      />
      </sc.TagWrapper> */}
      <sc.H2>{t("version_change_commit_message")}</sc.H2>
      <Input
        onChange={handleChangeCommit}
        placeholder="Commit message"
        type="text"
      />
      <sc.Spacer height="24px" />
      <sc.H2>{t("version_change_commit_changelog")}</sc.H2>
      <AppTextarea
        placeholder="Changelog"
        width="100%"
        height="120px"
        handleChange={handleChangelog}
      />
      <sc.BtnContainer>
        <sc.Previous
          onClick={handlePreviousClick}
        >
          {t("previous")}
        </sc.Previous>
        <Button
          label={t("next")}
          onClick={handleNextClick}
          small={true}
          disabled={!isFormValid}
        />
      </sc.BtnContainer>
    </sc.Container>
  )
}
