import * as sc from "./style";
import { useTranslation } from "react-i18next";
import { AppList } from "../List/AppList";

export const AppTabs = ({ actionsList, eventsList, contextList, handleClickSlidePanel }) => {
  const { t } = useTranslation();

  return (
    <sc.Container>
      <sc.List>
        <sc.TabItem>{t("actions")}</sc.TabItem>
        <sc.TabItem>{t("events")}</sc.TabItem>
        <sc.TabItem>{t("context")}</sc.TabItem>
      </sc.List>
      {
        actionsList ?
          (<sc.Panel>
            <AppList
              type="actions"
              list={actionsList}
              handleClickSlidePanel={handleClickSlidePanel}
            />
          </sc.Panel>)
          : (<p>{t("no_actions")}</p>)
      }
      {
        eventsList ?
          (<sc.Panel>
            <AppList
              type="events"
              list={eventsList}
              handleClickSlidePanel={handleClickSlidePanel}
            />
          </sc.Panel>)
          : (<p>{t("no_events")}</p>)
      }
      {
        contextList ?
          (<sc.Panel>
            <AppList
              type="context"
              list={contextList}
              handleClickSlidePanel={handleClickSlidePanel}
            />
          </sc.Panel>)
          : (<p>{t("no_contexts")}</p>)
      }
    </sc.Container>
  )
}