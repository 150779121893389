import React, { useState } from "react";
import PropTypes from "prop-types";
import { Eye, EyeOff, Search } from "react-feather";
import * as sc from "./Input.style";
import {ReactComponent as SearchIcon} from '../../assets/icons/search.svg';
import {ReactComponent as ClearIcon} from '../../assets/icons/clearIcon.svg';

export const Input = ({ type, placeholder, onChange, onKeyDown, isRequired, id, name, icon, register, value, onBlur, error, ...props }) => {
  const [toggleShowPassword, setToggleShowPassword] = useState(false);
  const [toggleIconSearch, setToggleIconSearch] = useState(false);

  const togglePassword = (e) => {
    e.preventDefault();
    setToggleShowPassword(!toggleShowPassword)
  }

  const toggleSearch = (e) => {
    e.target.value.length === 0
      ? setToggleIconSearch(false)
      : setToggleIconSearch(true);
  }

  const clearSearch = (e) => {
    document.querySelector('#search-app').value = "";
    onChange();
    setToggleIconSearch(false);
  }

  if (type === "password") {
    return (
      <sc.Password>
        <sc.Input id={id} name={name} type={toggleShowPassword ? "text" : type} placeholder={placeholder} onChange={onChange} isRequires={isRequired} autoComplete="false" ref={register} />
        <sc.ShowPassword onClick={e => togglePassword(e)} password={toggleShowPassword}>
          {
            toggleShowPassword
              ? <Eye />
              : <EyeOff />
          }
        </sc.ShowPassword>
      </sc.Password>
    )
  }

  if (icon) {
    return (
      <sc.WithIcon>
        <sc.Icon>
          <Search />
        </sc.Icon>
        <sc.Input id={id} type={type} name={name} placeholder={placeholder} onChange={onChange} isRequires={isRequired} icon={icon} ref={register} />
      </sc.WithIcon>
    )
  }

  if (type === "search") {
    return (
      <sc.SearchApp onChange={e => toggleSearch(e)}>
        <sc.Input
          id={id}
          name={name}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          onKeyDown={onKeyDown}
          isRequires={isRequired}
          autoComplete="false"
          ref={register}
        />
        <sc.IconSearch>
          {
            toggleIconSearch
              ? <ClearIcon onClick={e => clearSearch(e)}/>
              : <SearchIcon />
          }
        </sc.IconSearch>
      </sc.SearchApp>
    )
  }

  return (
    <sc.Input
      id={id}
      type={type}
      name={name}
      placeholder={placeholder}
      onChange={onChange}
      isRequires={isRequired}
      onBlur={onBlur}
      value={value}
      error={error}
      ref={register} />
  );
};

export default Input;

Input.propTypes = {
  type: PropTypes.oneOf(["text", "email", "password", "search"]).isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  isRequired: PropTypes.bool
};