import axios from 'axios';
import { getConfig } from '../helpers/getConfig';
const FACTORY_API_URL = process.env.REACT_APP_FACTORY_API_URL;

export const getApplication = async ({ token, applicationId }) => {
  if (!applicationId) {
    return;
  }
  try {
    const config = getConfig(token);
    const result = await axios.get(
      `${FACTORY_API_URL}/applications/${applicationId}`,
      config
    );

    return result.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};
