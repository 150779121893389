import styled from "styled-components";
import { colors } from "../../helpers/style/constants";
import { Link as a } from "react-router-dom";

export const Wrapper = styled.div`
  display: flex;
  margin: 0 0 85px 140px;
`;

export const Main = styled.div`
  width: calc(100% - 540px);
  margin-top: 43px;
`;

export const Side = styled.div`
  width: 320px;
  padding: 45px 50px;
  border-left: 1px solid ${colors.light_grey};
  height: 100%;
  display: flex;
  position: fixed;
  right: 0;
`;

export const IconAndName = styled.div`
  display: flex;
  align-items: center;
`;

export const VerticalSpacer = styled.div`
  margin-left: 30px;
`;

export const AppName = styled.h1`
  font-weight: 600;
  font-size: 24px;
  text-transform: capitalize;
  color: ${colors.text};
  overflow-wrap: anywhere;
  margin: -4px -8px;
`;

export const OwnerName = styled.p`
  font-size: 16px;
  color: ${colors.secondary_text};
  white-space: nowrap;
  font-weight: 500;
  margin: -1px -8px;
`;

export const DescriptionTitle = styled.h2`
  display: flex;
  font-size: 13px;
  color: ${colors.secondary_text};
  white-space: nowrap;
  font-weight: 600;
  margin: 0;
  align-items: center;
  text-transform: uppercase;

  & > svg {
    margin: 6px 10px 0 0;
  }

  & > svg > :nth-child(3), & > svg > :nth-child(4) {
    color: transparent;
  }
`;

export const Spacer = styled.div`
  height: ${p => p.height ? `${p.height}` : ""};
`;

export const DeploymentLink = styled(a)`
  text-decoration: none;
`;

export const Input = styled.input`
  background-color: ${colors.input_bg};
  border-radius: 6px;
  border: ${p => p.error ? "1px solid #ff5d55" : `1px solid ${colors.input_border}`};
  color: ${colors.text};
  font-size: 15px;
  font-weight: 400;
  outline: none;
  height: 48px;
  padding: 0 10px 0 10px;
  transition: .2s ease-out;
  width: calc(100% - 24px);

  &:focus {
    border: ${p => p.error ? "1px solid #ff5d55" : `1px solid ${colors.input_border_focus}`};
    box-shadow: 0 0 0 6px #f0f7ff;
  }

  &::placeholder {
    color: ${colors.input_placeholder};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 300px;
  margin-top: 20px;
`;

export const Cancel = styled.button`
  background-color: transparent;
  border: none;
  color: #78859d;
  font-size: 15px;
  font-weight: bold;
  transition: .1s ease-out;

  &:hover {
    color: #020253;
  }
`;

export const Spinner = styled.div`
  margin: -3px auto;
  padding: 11px;
  font-size: 10px;
  animation: load8 .9s infinite linear;
  transform: translateZ(0);
  border: 3px solid rgba(255, 255, 255, 0.2);
  border-left: 3px solid #fff;
  border-radius: 68px;

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

export const Error = styled.p`
  color: #e45655;
  font-weight: 600;
  font-size: 17px;
  margin: 3px 0 0px;
`;
