import * as sc from "./Textarea.style";

export const Textarea = ({ placeholder, value, onChange }) => {
  return (
    <sc.TextArea
      placeholder={placeholder}
      defaultValue={value}
      onChange={onChange}
      rows={3}
    />
  )
}