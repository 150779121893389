import { Button } from "../../../../Button/Button";
import { useTranslation } from "react-i18next";
import * as sc from "../../style";
import { VersionRadio } from "../../../../AppRadios/VersionRadio";

export default function View({ handleClick, handleChangeVersionType, versionType, displayedStep, maxStep, }) {
  const { t } = useTranslation();

  return (
    <sc.Container>
      <sc.Title>{t("create_new_version_btn")}</sc.Title>
      <sc.SubTitle>{t("step_of", { num: displayedStep, max: maxStep })}</sc.SubTitle>
      <sc.H2>{t("version_update_type")}</sc.H2>
      <VersionRadio 
        options={[
          {
            icon: 'ChevronsUp',
            value: 'major',
          },
          {
            icon: 'ChevronUp',
            value: 'minor',
          },
          {
            icon: 'Minus',
            value: 'fix',
          }
        ]}
        handleChange={handleChangeVersionType}
        selected={versionType} 
      />
      <sc.BtnContainer right={true}>
        <Button 
          label={t("next")}
          onClick={handleClick}
          small={true}
          disabled={!versionType}
        /> 
      </sc.BtnContainer>   
    </sc.Container>
  )
}