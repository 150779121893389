import { DeleteAppModal } from "../../components/Modal/DeleteAppModal";
import { PublishAppModal } from "../../components/Modal/PublishAppModal";
import { UploadImageModal } from "../../components/Modal/UploadImageModal";
import { MainWrapper } from "../../helpers/style/MainWrapper.style";
import { AppDetails } from "../../components/AppDetails";
import { AppTabs } from "../../components/AppTabs";
import { useTranslation } from "react-i18next";
import { humanizeZuluDate } from "../../helpers/humanizeDate";
import { AlignLeft } from "react-feather";
import { AppTag } from "../../components/AppTag";
import { AppEmptyState } from "../../components/AppEmptyState";
import { Logo } from "../../components/Logo/Logo";
import { SlidingPanel } from "../../components/SlidingPanel";
import { DeploymentBanner } from "../../components/DeploymentBanner";
import Spinner from "../../components/Loader/Spinner";
import Button from "../../components/Button/Button";
import { DeploymentErrors } from '../../components/DeploymentErrors';
import { Textarea } from "../../components/Textarea/Textarea";
import { ExpandableText } from "../../components/ExpandableText/ExpandableText";
import Modal from "react-responsive-modal";
import * as sc from "./style";

export default function View({ appWithVersion, handleChangeVersion, isLoading, application, openDeleteAppModal, onOpenDeleteAppModal, onCloseDeleteAppModal, openPublishAppModal, onOpenPublishAppModal, onClosePublishAppModal, handleChangeDeleteValue, deleteValue, disabledDelete, handlePublish, handleDeleteDraft, handleDeleteVersion, isDeleting, openSlidePanel, closeSlidePanel, isPublishing, handleClickSlidePanel, selectedInputs, handleSaveNewInputs, isSaving, isEditingDescription, handleEditingDescription, handleChangeDescription, handleCancelEditingDescription, handleSaveNewDescription, descriptionError, isUpdating, onOpenUploadImageModal, openUploadImageModal, onCloseUploadImageModal, replaceAppImage, openErrorModal, onCloseErrorModal, errorModalContent, retryDeployment, isRetrying, onOpenErrorModal, deploymentInProgress }) {
  const { t, i18n } = useTranslation();

  const {
    applicationName,
    applicationOwner,
    applicationDescription,
    applicationIconUrl,
    applicationSubcategories,
    currentVersionVisibility,
    currentVersionActionsCount,
    currentVersionEventsCount,
    currentVersionActions,
    currentVersionEvents,
    currentVersionContexts,
    currentVersionZip,
    applicationUpdatedAt,
    applicationCurrentVersion,
    applicationAllVersions,
    applicationId,
    deployments
  } = application;

  if (isLoading) {
    return (
      <MainWrapper>
        <Spinner />
      </MainWrapper>
    );
  }

  return (
    <>
      <MainWrapper>
        <sc.Wrapper>
          <sc.Main>
            {/* PRESENTATION */}
            <div>
              {/* ICON & NAME */}
              <sc.IconAndName>
                <Logo
                  name={applicationName}
                  url={applicationIconUrl}
                  size="large"
                  editable={true}
                  onOpenUploadImageModal={onOpenUploadImageModal}
                />
                <sc.VerticalSpacer>
                  <sc.AppName>{applicationName}</sc.AppName>
                  <sc.OwnerName>{t("by")} {applicationOwner}</sc.OwnerName>
                </sc.VerticalSpacer>
              </sc.IconAndName>
              <sc.Spacer height="30px" />
              {/* DESCRIPTION */}
              <div>
                <sc.DescriptionTitle><AlignLeft />{t("description")}</sc.DescriptionTitle>
                <sc.Spacer height="10px" />
                {isEditingDescription
                  ? (
                    <>
                      <Textarea
                        placeholder={t("app_description_placeholder")}
                        value={applicationDescription}
                        onChange={handleChangeDescription}
                      />
                      <div>
                        {descriptionError && <sc.Error>{descriptionError}</sc.Error>}
                      </div>
                      <sc.ButtonsContainer>
                        <Button
                          label={isUpdating ? <sc.Spinner /> : "Save"}
                          onClick={handleSaveNewDescription}
                          small
                        />
                        <sc.Cancel onClick={handleCancelEditingDescription}>Cancel</sc.Cancel>
                      </sc.ButtonsContainer>
                    </>
                  )
                  : (
                    <ExpandableText
                      maxLength={180}
                      onClick={handleEditingDescription}
                    >
                      {applicationDescription}
                    </ExpandableText>
                  )
                }
              </div>
            </div>
            <sc.Spacer height="20px" />
            <div>
              {deployments?.map(item => (
                <sc.DeploymentLink key={item.id} to={item.status === "failed" ? "#" : "/deployments"}
                  onClick={() => { onOpenErrorModal(item) }}
                >
                  <DeploymentBanner
                    status={item.status}
                    version={item.version}
                  />
                </sc.DeploymentLink>
              )
              )
              }
            </div>
            <sc.Spacer height="40px" />
            {currentVersionVisibility === "draft"
              ? (<>
                <sc.Spacer height="40px" />
                <AppEmptyState
                  title={t("no_version_title")}
                  content={t("no_version_content")}
                />
              </>)
              : (<AppTabs
                handleClickSlidePanel={handleClickSlidePanel}
                actionsList={currentVersionActions}
                eventsList={currentVersionEvents}
                contextList={currentVersionContexts}
              />)
            }
          </sc.Main>
          <sc.Side>
            <AppDetails
              appWithVersion={appWithVersion}
              handleChangeVersion={handleChangeVersion}
              status={currentVersionVisibility}
              updatedAt={applicationUpdatedAt ? humanizeZuluDate(i18n, applicationUpdatedAt) : t("updated_long_time_ago")}
              actionsCount={currentVersionActionsCount}
              eventsCount={currentVersionEventsCount}
              defaultVersion={applicationCurrentVersion}
              versions={applicationAllVersions}
              appId={applicationId}
              zipUrl={currentVersionZip}
              onOpenDeleteAppModal={onOpenDeleteAppModal}
              onOpenPublishAppModal={onOpenPublishAppModal}
              deploymentInProgress={deploymentInProgress}
            />
          </sc.Side>
        </sc.Wrapper>
        {selectedInputs &&
          <SlidingPanel
            openSlidePanel={openSlidePanel}
            closeSlidePanel={closeSlidePanel}
            handleClickSlidePanel={handleClickSlidePanel}
            selectedInputs={selectedInputs}
            handleSaveNewInputs={handleSaveNewInputs}
            isSaving={isSaving}
          />
        }
      </MainWrapper>
      <DeleteAppModal
        application={application}
        defaultVersion={applicationCurrentVersion}
        open={openDeleteAppModal}
        onCloseModal={onCloseDeleteAppModal}
        handleChangeDeleteValue={handleChangeDeleteValue}
        deleteValue={deleteValue}
        disabled={disabledDelete}
        handleDeleteDraft={handleDeleteDraft}
        handleDeleteVersion={handleDeleteVersion}
        isDeleting={isDeleting}
      />
      <PublishAppModal
        open={openPublishAppModal}
        onCloseModal={onClosePublishAppModal}
        handlePublish={handlePublish}
        isPublishing={isPublishing}
      />
      <UploadImageModal
        open={openUploadImageModal}
        onCloseModal={onCloseUploadImageModal}
        applicationId={applicationId}
        replaceAppImage={replaceAppImage}
      />

      <Modal
        open={openErrorModal}
        onClose={onCloseErrorModal}
        center
        classNames={{
          overlay: "custom-overlay",
          modal: "custom-modal"
        }}
      >
        <DeploymentErrors
          appName={errorModalContent.name}
          icon={errorModalContent.icon}
          deploymentId={errorModalContent.id}
          version={errorModalContent.version}
          errors={errorModalContent.errors}
          retryDeployment={retryDeployment}
          isLoading={isRetrying}
          onCloseModal={onCloseErrorModal}
        />
      </Modal>
    </>
  );
}
