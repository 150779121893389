import { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { KeycloakContext } from "../../../../../auth/AuthWrapper";
import * as service from "./service";
import View from "./View";

export const Step4 = ({values, onPrevious, globalStepValues, displayedStep, maxStep, closeModal, appId}) => {
  const history = useHistory();
  const [file, setFile] = useState(values?.file || null);
  const [state] = useContext(KeycloakContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeFile = async (file) => {
    if (file) {
      setIsLoading(true);
      setFile(file);
      setIsLoading(false);
    }
  }

  const handleNextClick = async () => {
    try {
      setIsLoading(true);
      await service.postDeployment(state.keycloak.token, globalStepValues, file, appId);
    } catch(e){
      setFile(null);
    } finally {
      history.push({ pathname: "/deployments" });
      setIsLoading(false);
    }
  };

  const handlePreviousClick = () => {
    onPrevious();
  };

  const params = { 
    handleNextClick,
    handlePreviousClick,
    maxStep,
    displayedStep,
    handleChangeFile,
    isLoading,
    file
  };

  return <View {...params} />
}