import { useTranslation } from "react-i18next";
import * as sc from "../../style";

export default function View({ slugName, handleRedirect, handleSelectFile, setEditorRef, preview, handleUpload, isUploading }) {
  const { t } = useTranslation();
  return (
    <sc.Container>
      <sc.CenteringImage>
        <sc.ImageBorderContainer image={preview}>
          { preview ? (
            /** Display image if selected by user */
            <sc.ImageContainer
              ref={setEditorRef}
              image={preview}
              width={145}
              height={145}
              border={0}
              rotate={0}
            />
            ) : (
              /** Display app slugName's initial if no image selected yet */
              <sc.AppInitial>
                {_getAppNameInitial(slugName)}
              </sc.AppInitial>
            )
          }
        </sc.ImageBorderContainer>
      </sc.CenteringImage>
      <sc.UploadButtonContainer>
        <sc.UploadButton htmlFor="file" className="label-file" preview={preview}>
          {preview ? t("change_icon") : t("upload_app_icon")}
        </sc.UploadButton>
        <sc.InputFile
          id='file'
          className='input-file'
          type='file'
          accept='.png, .jpg, .jpeg'
          onChange={handleSelectFile}
        />
        {preview &&
          <sc.UploadButton onClick={handleUpload}>
            {isUploading ? <sc.Spinner /> : t("continue")}
          </sc.UploadButton>
        }
      </sc.UploadButtonContainer>
      <sc.Spacer height={"24px"}/>
      <sc.Skip onClick={handleRedirect}>
        {t("skip_step")}
      </sc.Skip>
      <sc.Spacer height={"10px"}/>
    </sc.Container>
  )
}

const _getAppNameInitial = slugName => slugName?.slice(0, 1).toUpperCase();
