import * as mapper from "./mapper";

import axios from "axios";
import { getConfig } from "../../helpers/getConfig";

const FACTORY_API_URL = process.env.REACT_APP_FACTORY_API_URL;
const SERVERLESS_FACTORY_API_URL = process.env.REACT_APP_SERVERLESS_FACTORY_API_URL;

/**
 * List all applications with pagination of a specific user
 * @param  {String} token The keycloak token
 * @param nextPageToken the next page token
 * @return {Array}        The list of applications
 */
export const listApplicationsWithPagination = async (token, nextPageToken, searchName) => {
    try {
        const config = getConfig(token);
        const url = window.location.href;
        const hostname = window.location.hostname;
        const isLocal = url.includes("localhost");
        let tenant;

        if (!isLocal && hostname.indexOf('.marjory.io') !== -1) {
            /* read first string before marjory.io */
            [tenant] = hostname.split('.');
        } else {
            /* local env (no marjory.io in hostname) */
            tenant = "product";
        }
        const appsList = !searchName
        ? nextPageToken
            ? await axios.get(`${SERVERLESS_FACTORY_API_URL}/appCatalog/apps?limit=25&nextPageToken=${nextPageToken}&tenant=${tenant}&visibility=TENANT&visibility=CUSTOMER&visibility=PUBLIC`, config)
            : await axios.get(`${SERVERLESS_FACTORY_API_URL}/appCatalog/apps?limit=25&tenant=${tenant}&visibility=TENANT&visibility=CUSTOMER&visibility=PUBLIC`, config)
        : nextPageToken
            ? await axios.get(`${SERVERLESS_FACTORY_API_URL}/appCatalog/apps?name=${searchName}&limit=25&nextPageToken=${nextPageToken}&tenant=${tenant}&visibility=TENANT&visibility=CUSTOMER&visibility=PUBLIC`, config)
            : await axios.get(`${SERVERLESS_FACTORY_API_URL}/appCatalog/apps?name=${searchName}&limit=25&tenant=${tenant}&visibility=TENANT&visibility=CUSTOMER&visibility=PUBLIC`, config);

        return {
            apps: mapper.apps(appsList.data),
            nextPageToken: appsList.data.pagination.nextPageToken,
        }
    } catch (err) {
        console.error(err);
        throw err;
    }
}

/**
 * (Legacy) List all applications with no pagination of a specific user
 * @param  {String} token The keycloak token
 * @return {Array}        The list of applications
 */
export const legacy_listApplications = async (token) => {
    try {
        const config = getConfig(token);
        const applicationsList = await axios.get(`${FACTORY_API_URL}/applications?accessType=owner`, config);
        return mapper.legacy_apps(applicationsList.data);
    } catch (err) {
        console.error(err);
        throw err;
    }

}
