import styled from "styled-components";
import { colors } from "../../helpers/style/constants";

export const Box = styled.div`
  background-color: ${p => p.usage === "workflow"
    ? colors.workflow_background
    : p.usage === "webService"
      ? colors.web_service_background
      : colors.external_background
  };
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${p => p.size === "lg"
    ? "56px"
    : p.size === "md"
      ? "34px"
      : "28px"
  };
  height: ${p => p.size === "lg"
    ? "56px"
    : p.size === "md"
      ? "34px"
      : "28px"
  };

  & > svg {
    color: ${p => p.usage === "workflow"
    ? colors.workflow_icon
    : p.usage === "webService"
      ? colors.web_service_icon
      : colors.external_icon
  };
  width: ${p => p.size === "lg"
    ? "23px"
    : p.size === "md"
      ? "17px"
      : "15px"
  };
  };
`;

export const OneUsage = styled.div`
  
`;

export const TwoUsages = styled.div`
  position: relative;
  width: 56px;
  height: 56px;

  > :nth-child(1) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
  }

  > :nth-child(2) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    position: absolute;
    right: 0px;
    bottom: 0px;
  }
`;

export const ThreeUsages = styled.div`
  position: relative;
  width: 56px;
  height: 56px;

  > :nth-child(1){
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
  }

  > :nth-child(2) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    position: absolute;
    margin: 14px -25%;
  }

  > :nth-child(3) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    position: absolute;
    right: 0px;
    bottom: 0px;
  }
`;