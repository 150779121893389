import * as sc from "./style";

export const AppTextarea = ({placeholder, width, height, handleChange}) => {
  return (
      <sc.AppTextarea
          placeholder={placeholder}
          width={width}
          height={height}
          onChange={handleChange}
      />  
  )
}