import styled from "styled-components";

export const MainWrapper = styled.div`
  position: absolute;
  /* margin: 94px 0 0 280px;
  width: calc(100% - 320px); */
  margin: 70px 0 0 240px;
  width: calc(100% - 240px);
  z-index: -1;
`;

export const MainContainer = styled.div`
  margin: 30px;
`;

export const EmptyContainer = styled.div`
  margin: 30px auto;
  display: flex;
  align-items: center;
  flex-direction: column;
`;