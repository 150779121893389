import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { KeycloakContext } from "../../../auth/AuthWrapper";
import { UploadCloud } from "react-feather";
import * as service from "./service";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import "../../../helpers/style/custom-modal.css";
import * as sc from "./style";

export const UploadImageModal = ({ open, onCloseModal, applicationId, replaceAppImage }) => {
  const { t } = useTranslation();

  let editor = "";
  const setEditorRef = ed => editor = ed;

  const [state] = useContext(KeycloakContext);
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (!image) return setPreview(null);

    const objectUrl = URL.createObjectURL(image);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [image]);

  // NOTE Triggered when user selects a file from the computer
  const handleSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      return setImage(null);
    }

    const file = e.target.files[0];

    if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
      setImage(file);
    } else {
      setPreview(null);
    }
  };

  // NOTE Upload icon and redirect user
  const handleUpload = async () => {
    setIsUploading(true);
    if (setEditorRef) {
      const canvasScaled = editor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();
      const token = state.keycloak.token;
      await service.uploadLogo(applicationId, croppedImg, token);
      replaceAppImage(croppedImg);
      setIsUploading(false);
      setImage(null);
      onCloseModal();
    }
  }

  // NOTE Remove selected image if user cancel the upload
  const removeImageSelected = () => {
    setImage(null);
    setIsUploading(false);
    return;
  }

  return (
    <Modal
      open={open}
      onClose={() =>{ onCloseModal(); removeImageSelected();}}
      center
      classNames={{
        overlay: "custom-overlay",
        modal: "custom-modal"
      }}
    >
      <sc.ModalWrapper>
      <sc.Container>
      <sc.CenteringImage>
        <sc.ImageBorderContainer image={preview}>
          { preview ? ( 
            /** Display image if selected by user */
            <sc.ImageContainer
              ref={setEditorRef}
              image={preview}
              width={145}
              height={145}
              border={0}
              rotate={0}
            />
            ) : (
              <sc.ImageContent>
                <UploadCloud/>
              </sc.ImageContent>
            )
          }
        </sc.ImageBorderContainer>
      </sc.CenteringImage>
      <sc.Spacer height={"10px"}/>
      <sc.UploadButtonContainer>
        <sc.UploadButton htmlFor="file" className="label-file" preview={preview}>
          {preview ? t("change_icon") : t("upload_app_icon")}
        </sc.UploadButton>
        <sc.InputFile
          id='file'
          className='input-file'
          type='file'
          accept='.png, .jpg, .jpeg'
          onChange={handleSelectFile}
        />
        {preview &&
          <sc.UploadButton onClick={handleUpload}>
            {isUploading ? <sc.Spinner /> : t("confirm")}
          </sc.UploadButton> 
        }
      </sc.UploadButtonContainer>
      <sc.Spacer height="10px"/>
    </sc.Container>
      </sc.ModalWrapper>
    </Modal >
  )
}