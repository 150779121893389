import * as service from './service'

import { useContext, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { KeycloakContext } from '../../auth/AuthWrapper';
import View from './View';
import { getDeployments } from '../../api/deployments.api';
import { useHistory } from 'react-router-dom';

export default function Deployments() {
  const history = useHistory();
  const [state] = useContext(KeycloakContext);
  const [isRetrying, setIsRetrying] = useState(null);
  const [, setRefech] = useState(false);
  const queryClient = useQueryClient()

  const { data: deployments, isFetching } = useQuery('deployments',
    () => getDeployments(state.keycloak.token),
    { refetchOnWindowFocus: false }
  );

   // NOTE Triggered when user retry deployment
  const retryDeployment = async (deploymentId, e) => {
    setIsRetrying(true);
    try {
      const token = state.keycloak.token;
      await service.retryDeployment(token, deploymentId, e.target.files[0]);
      queryClient.invalidateQueries("deployments");
    } finally {
      setRefech(true);
      setIsRetrying(false);
    }
  }

  // NOTE Triggered when the page is loaded
  useEffect(() => {
    !state.userRoles && history.push('/error/500');
  }, [state.userRoles, history]);

  const params = {
    isLoading: isFetching,
    deployments,
    isRetrying,
    retryDeployment
  };
  return <View {...params} />;
}
