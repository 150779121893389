import { PanelAccordion } from "./PanelAccordion";
import * as sc from "./style";

export const PanelMain = ({ header, data, handleChange }) => {
  switch (header.type) {
    case "actions":
      return (
        <sc.Container>
          <sc.List>
            <sc.TabItem>Inputs</sc.TabItem>
            <sc.TabItem disabled>Filters</sc.TabItem>
            <sc.TabItem disabled>Outputs</sc.TabItem>
          </sc.List>
          <sc.PanelAccordionWrapper>
          {!data || !data.length
            ? <p>No input found.</p>
            : data.map((input, index) => (
              <PanelAccordion
                key={index}
                index={index}
                input={input}
                handleChange={handleChange}
              />
            ))
          }
          </sc.PanelAccordionWrapper>
        </sc.Container>
      )
    case "events":
      return (
        <sc.Container>
          <sc.List>
            <sc.TabItem>Payloads</sc.TabItem>
          </sc.List>
          <sc.PanelAccordionWrapper>
          {!data || !data.length
            ? <p>No input found.</p>
            : data.map((input, index) => (
              <PanelAccordion
                key={index}
                index={index}
                input={input}
                handleChange={handleChange}
              />
            ))
          }
          </sc.PanelAccordionWrapper>
        </sc.Container>
      )
    default:
      return <></>;
  }
}
