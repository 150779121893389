import * as sc from "./style";
import { Box } from "react-feather";

export function AppEmptyState({ title, content }) {

  return (
    <sc.Wrapper>
      <sc.Icon>
        <Box />
      </sc.Icon>
      <sc.Spacer height="20px" />
      <sc.Title>{title}</sc.Title>
      <sc.Spacer height="10px" />
      <sc.Content>{content}</sc.Content>
    </sc.Wrapper>
  )
}