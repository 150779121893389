import { ActionIcon } from "../../ActionIcon/ActionIcon";
import { Clock, Lock, Unlock, Zap } from "react-feather";
import { css } from "styled-components";
import Tooltip from "react-simple-tooltip";
import * as sc from "./style";

export const AppList = ({ type, list, handleClickSlidePanel }) => {

  if (type === "actions") {
    return (
      <sc.ListContainer>
        {list.map((item, i) => {
          return (
            <sc.ListItem key={i} onClick={() => handleClickSlidePanel("actions", item.name)}>
              <div>
                {_actionIconType(item)}
              </div>
              <sc.InfoContainer>
                <sc.Name>{item.name}</sc.Name>
                <sc.Description>{item.description}</sc.Description>
              </sc.InfoContainer>
            </sc.ListItem>
          )
        })}
      </sc.ListContainer>
    )
  }

  if (type === "events") {
    return (
      <sc.ListContainer>
        {list.map((item, i) => {
          return (
            <sc.ListItem key={i} onClick={() => handleClickSlidePanel("events", item.name)}>
              <div>
                {_eventIconType(item)}
              </div>
              <sc.InfoContainer>
                <sc.Name>{item.name}</sc.Name>
                <sc.Description>{item.description}</sc.Description>
              </sc.InfoContainer>
            </sc.ListItem>
          )
        })}
      </sc.ListContainer>
    )
  }

  if (type === "context") {
    return (
      <sc.ListContainer>
        {list.map((item, i) => {
          return (
            <sc.ListItem key={i} onClick={() => handleClickSlidePanel("context", item.name)}>
              <div>
                <div>
                  {_contextIconType(item)}
                </div>
              </div>
              <sc.InfoContainer>
                <sc.Name>{item.name}</sc.Name>
                <sc.Description>{item.description}</sc.Description>
              </sc.InfoContainer>
            </sc.ListItem>
          )
        })}
      </sc.ListContainer>
    )
  }
}

const _actionIconType = item => {
  const content = ({ usage }) => {
    if (usage.length === 1) {
      if (usage[0] === "workflow") return "Workflow";
      if (usage[0] === "webService") return "Web service";
    }

    if (usage.length === 2) {
      if (usage[0] === "workflow" && usage[1] === "webService") return "Workflow & Web service";
      if (usage[0] === "workflow" && usage[1] === "external") return "Workflow & External";
      if (usage[0] === "webService" && usage[1] === "external") return "Web service & External";
      if (usage[0] === "webService" && usage[1] === "workflow") return "Web service & Workflow";
      if (usage[0] === "external" && usage[1] === "workflow") return "External & Workflow";
      if (usage[0] === "external" && usage[1] === "webService") return "External & Web service";
    }

    if (usage.length === 3) {
      return "Workflow, Web service & External"
    }
  }

  return (
    <Tooltip
      content={content(item)}
      background="#161c39"
      fadeDuration={200}
      fontFamily="Manrope"
      fontSize="14px"
      padding={10}
      radius={6}
      customCss={css`
        white-space: nowrap;
      `}
    >
      <ActionIcon list={item.usage} />
    </Tooltip>
  )
}

const _eventIconType = item => {
  const content = ({ usage }) => {
    if (usage.length === 1) {
      if (usage[0] === "webhook") {
        return "Webhook"
      }

      if (usage[0] === "scheduled") {
        return "Scheduled"
      }
    }

    if (usage.length === 2) {
      return "Webhook & scheduled"
    }
  }

  return (
    <Tooltip
      content={content(item)}
      background="#161c39"
      fadeDuration={200}
      fontFamily="Manrope"
      fontSize="14px"
      padding={10}
      radius={6}
      customCss={css`
        white-space: nowrap;
      `}
    >
      {item.usage.length === 1 && item.usage[0] === "webhook" && (
        <sc.IconEvent type={item.usage[0]}><Zap /></sc.IconEvent>
      )}
      {item.usage.length === 1 && item.usage[0] === "scheduled" && (
        <sc.IconEvent type={item.usage[0]}><Clock /></sc.IconEvent>
      )}
      {item.usage.length === 2 && (
        <sc.DoubleIconContainer>
          <sc.DoubleIconTopEvent type="webhook">
            <Zap />
          </sc.DoubleIconTopEvent>
          <sc.DoubleIconBottomEvent type="scheduled">
            <Clock />
          </sc.DoubleIconBottomEvent>
        </sc.DoubleIconContainer>
      )}
    </Tooltip>
  )
}

const _contextIconType = item => {
  return (
    <Tooltip
      content={item.isCrypted ? "Crypted" : "Not crypted"}
      background="#161c39"
      fadeDuration={200}
      fontFamily="Manrope"
      fontSize="14px"
      padding={10}
      radius={6}
      customCss={css`white-space: nowrap;`}
    >
      {item.isCrypted && (
        <sc.IconContext isCrypted={item.isCrypted}><Lock /></sc.IconContext>
      )}
      {!item.isCrypted && (
        <sc.IconContext isCrypted={item.isCrypted}><Unlock /></sc.IconContext>
      )}
    </Tooltip>
  )
}
