import * as deploymentService from "../Deployments/service"
import * as service from "./service";

import { useContext, useEffect, useState } from "react";

import { KeycloakContext } from "../../auth/AuthWrapper";
import View from "./View";
import { onError } from "../../helpers/onError";
import { useHistory } from "react-router-dom";
import { useQueryClient } from "react-query";

export default function Application(props) {
  const history = useHistory();
  const queryClient = useQueryClient();
  const [state] = useContext(KeycloakContext);
  const [isLoading, setIsLoading] = useState(true);
  const [appWithVersion, setAppWithVersion] = useState(null);
  const [application, setApplication] = useState({});
  const [openDeleteAppModal, setOpenDeleteAppModal] = useState(false);
  const [openPublishAppModal, setOpenPublishAppModal] = useState(false);
  const [openUploadImageModal, setOpenUploadImageModal] = useState(false);
  const [deploymentInProgress, setDeploymentInProgress] = useState(false);
  const [deleteValue, setDeleteValue] = useState("");
  const [disabledDelete, setDisabledDelete] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [openSlidePanel, setOpenSlidePanel] = useState(false);
  const [selectedInputs, setSelectedInputs] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [currentDescription, setCurrentDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorModalContent, setErrorModalContent] = useState({});
  const [isRetrying, setIsRetrying] = useState(null);

  const onOpenErrorModal = item => {
    setOpenErrorModal(true);
    setErrorModalContent(item);
  }

  const onCloseErrorModal = () => {
    setOpenErrorModal(false);
  }

  const retryDeployment = async (deploymentId, e) => {
    setIsRetrying(true);
    try {
      const token = state.keycloak.token;
      await deploymentService.retryDeployment(token, deploymentId, e.target.files[0]);
      queryClient.invalidateQueries('deployments')
    } finally {
      setIsRetrying(false);
      onCloseErrorModal();
    }
  }

  // NOTE Triggered when the page is loaded
  useEffect(() => {
    !state.userRoles && history.push("/error/403");
  }, [state.userRoles, history]);

  useEffect(() => {
    setIsLoading(true);

    const fetchData = async () => {
      try {
          const versionInUrl = props.match.params.version;
          const token = state.keycloak.token;
          const appName = props.match.params.applicationName;

          let app;
          if (versionInUrl) app = await service.getApplicationByNameAndVersion(appName, versionInUrl, token);
          else app = await service.getApplicationByName(appName, token);

          // If a deployent is in progress, the deploy a new version button is disabled
          const hasDeploymentInProgress = app.deployments?.some(
            (deployment) => deployment.status === "starting" || deployment.status === "in_progress" 
          );
          setDeploymentInProgress(hasDeploymentInProgress);
          setApplication(app);
        } catch(error) {
          onError(error, state);
        } finally {
          setIsLoading(false);
          setRefetch(false);
        }
      }
      fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.applicationName, state, state.keycloak.token, refetch, appWithVersion]);


  // NOTE Open slide modal to edit specific action, event or context inputs/outputs
  const handleClickSlidePanel = (type, target) => {
    let selected;
    if (type === "actions") { 
      selected = application.currentVersionActions.filter(item => item.name === target);
    } else if (type === "events") {
      selected = application.currentVersionEvents.filter(item => item.name === target);
    } else {
      selected = application.currentVersionContexts.filter(item => item.name === target);
    }

    setSelectedInputs(selected[0]);
    setOpenSlidePanel(!openSlidePanel);
  };

  // NOTE Open/close slide modal
  const closeSlidePanel = () => {
    setOpenSlidePanel(false);
    setSelectedInputs(null);
  }

  // NOTE Triggered when user save inputs change from actions, events or context
  const handleSaveNewInputs = async (header, inputsList) => {
    setIsSaving(true);
    const versionId = application.applicationCurrentVersion;

    let payload;
    if (header.type === "events") payload = { ...header, payload: inputsList };
    else payload = { ...header, inputs: inputsList };

    try {
      const token = state.keycloak.token;
      await service.updateApplicationInputs(versionId, payload, token)
      setRefetch(!refetch);
    } catch (error) {
      onError(error, state);
    } finally {
      setSelectedInputs(null);
      closeSlidePanel();
      setIsSaving(false);
    }
  }

  // NOTE Handle the delete app modal
  const onOpenDeleteAppModal = () => setOpenDeleteAppModal(true);
  const onCloseDeleteAppModal = () => !isDeleting && setOpenDeleteAppModal(false);

  // NOTE Handle the publish app modal
  const onOpenPublishAppModal = () => setOpenPublishAppModal(true);
  const onClosePublishAppModal = () => !isPublishing && setOpenPublishAppModal(false);

  // NOTE Handle the upload image modal
  const onOpenUploadImageModal = () => setOpenUploadImageModal(true);
  const onCloseUploadImageModal = () => setOpenUploadImageModal(false);

  // NOTE Check if the input is equal to app name in order to let user delete it
  const handleChangeDeleteValue = e => {
    setDeleteValue(e.target.value);
    const appName = application.applicationName.toLowerCase();
    const deleteValue = e.target.value.toLowerCase();
    appName === deleteValue
      ? setDisabledDelete(false)
      : setDisabledDelete(true);
  }

  // NOTE API call to delete a draft
  const handleDeleteDraft = async () => {
    try {
      setIsDeleting(true);
      const token = state.keycloak.token;
      const applicationId = application.applicationId;
      await service.deleteDraftApplication(applicationId, token);
      queryClient.invalidateQueries("applications");

      queryClient.setQueryData("applications", data => {
        return data.filter(app => app.application_id !== applicationId);
      });

    } catch (error) {
      onError(error, state);
    } finally {
      history.push({ pathname: "/apps" });
      setIsDeleting(false);
    }
  }

  // NOTE API call to delete an app version
  const handleDeleteVersion = async () => {
    try {
      setIsDeleting(true);
      const token = state.keycloak.token;
      const versionId = application.applicationCurrentVersion;
      await service.deleteVersionApplication(versionId, token);
      queryClient.invalidateQueries("applications");
    } catch (error) {
      onError(error, state);
    } finally {
      history.push({ pathname: `/apps/${props.match.params.applicationName}` });
      setIsDeleting(false);
      onCloseDeleteAppModal();
      setDeleteValue("");
      setDisabledDelete(true);
      setRefetch(true);
    }
  }

  // NOTE API call to publish an app version
  const handlePublish = async () => {
    try {
      setIsPublishing(true);
      const token = state.keycloak.token;
      const versionId = application.applicationCurrentVersion;
      await service.publishVersionApplication(versionId, token);
      queryClient.invalidateQueries("applications");
    } finally {
      setIsPublishing(false);
      onClosePublishAppModal();
      setRefetch(true);
    }
  }

  // NOTE Set url with specific version
  const handleChangeVersion = async e => {
    history.push({ pathname: `/apps/${props.match.params.applicationName}/v/${e.target.value}` });
    setAppWithVersion(e.target.value);
  }

  const handleEditingDescription = () => {
    setCurrentDescription(application.applicationDescription);
    setIsEditingDescription(true);
  }

  const handleCancelEditingDescription = () => {
    setIsEditingDescription(false);
    setDescriptionError("");
    setApplication({
      ...application,
      applicationDescription: currentDescription,
    })
  }

  const handleChangeDescription = e => {
    setApplication({
      ...application,
      applicationDescription: e.target.value,
    })
  }

  const handleSaveNewDescription = async () => {
    if (!application.applicationDescription) {
      setDescriptionError("Description field can't be empty");
      return;
    }

    if (currentDescription !== application.applicationDescription) {
      setIsUpdating(true);
      const token = state.keycloak.token;
      const applicationId = application.applicationId;
      const newDescription = application.applicationDescription;

      await service.updateApplication(applicationId, newDescription, token);

      setIsEditingDescription(false);
      setIsUpdating(false);
    } else {
      setIsEditingDescription(false);
    }
  }

  const replaceAppImage = imageUrl => {
    setApplication({
      ...application,
      applicationIconUrl: imageUrl,
    })
  }

  // NOTE Rendering component
  const params = {
    handleChangeVersion,
    appWithVersion,
    isLoading,
    application,
    openDeleteAppModal,
    onOpenDeleteAppModal,
    onCloseDeleteAppModal,
    openPublishAppModal,
    onOpenPublishAppModal,
    onClosePublishAppModal,
    handleChangeDeleteValue,
    deleteValue,
    disabledDelete,
    handleDeleteDraft,
    handleDeleteVersion,
    isDeleting,
    openSlidePanel,
    closeSlidePanel,
    isPublishing,
    handlePublish,
    handleClickSlidePanel,
    selectedInputs,
    handleSaveNewInputs,
    isSaving,
    isEditingDescription,
    handleEditingDescription,
    handleChangeDescription,
    handleCancelEditingDescription,
    handleSaveNewDescription,
    descriptionError,
    isUpdating,
    openUploadImageModal,
    onOpenUploadImageModal,
    onCloseUploadImageModal,
    replaceAppImage,
    openErrorModal,
    onCloseErrorModal,
    errorModalContent,
    retryDeployment,
    isRetrying,
    onOpenErrorModal,
    deploymentInProgress
  };

  return <View {...params} />
}
