export const getCoreUrl = url => {
const posHttp = url.indexOf("://");

if (posHttp !== -1) url = url.substring(posHttp+3);
const posLocalhost = url.indexOf("localhost");
const isLocal = posLocalhost === 0;

let environment;
const allowedEnv = ["dev", "staging", "preprod"];

if (isLocal) {
    environment = "staging";
} else {
    const env = url.split(".")[1];

    if (allowedEnv.includes(env)) {
    environment = env;
    } else {
    environment = "prod";
    }
}

return environment;
}