import * as sc from "./style";
import { useTranslation } from "react-i18next";
import { Logo } from "../Logo/Logo";
// import { useEffect } from "react";

export const DeploymentErrors = ({ appName, icon, deploymentId, version, errors, retryDeployment, isLoading, onCloseModal }) => {
  // useEffect(() => {
    // isLoading === false && onCloseModal();
  // });

  const { t } = useTranslation();
  return (
    <sc.Wrapper>
      <sc.AppContent>
        <div>
          <Logo 
            name={appName}
            url={icon}
            size={"medium"}
          />
        </div>
        <sc.AppTitle>
          <sc.Name>{appName}</sc.Name>
          <sc.ErrorVersion>{t("deployment_version_failed", { version })}</sc.ErrorVersion>
        </sc.AppTitle>
      </sc.AppContent>
      <sc.Spacer height="20px" />
      <div>
        <sc.ErrorTitle>
          {t("deployment_error_count", { count: errors.length })} :
        </sc.ErrorTitle>
        <ul>
          {errors.map((item, i) =>
            <sc.ErrorElement key={i}>
              {item}
            </sc.ErrorElement>
          )}
        </ul>
      </div>
      <sc.Spacer height="20px" />
      <sc.ButtonContainer>
      <sc.UploadButton htmlFor="file" className="label-file">
        {isLoading ? <sc.Spinner /> : t("deployment_new_zip_button")}
      </sc.UploadButton>
        <sc.InputFile
          disabled={isLoading}
          id='file'
          className='input-file'
          type='file'
          accept='.zip'
          onChange={e => retryDeployment(deploymentId, e)}
        />
      </sc.ButtonContainer>
      <sc.Spacer height="20px" />
    </sc.Wrapper>
  )
}
