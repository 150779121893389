import styled from "styled-components";
import { colors } from "../../../helpers/style/constants";

export const Container = styled.div`
  margin: 30px 0 20px 0;
  display: grid;
`;

export const Title = styled.h2`
  display: flex;
  margin-bottom:5px;
  font-size: 20px;
  color: ${colors.text};
`;

export const Alert = styled.p`
  width: 540px !important;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  color:${colors.secondary_text};
  line-height: 1.6;
`;

export const H3 = styled.h3`
  font-size: 18px;
  color: ${colors.text};
  margin-bottom: 24px;
`;

export const BtnContainer = styled.div`
  display: flex;
  justify-content: ${p => p.right ? "flex-end" : "space-between"};
  margin-top: 30px;
`;

export const Spacer = styled.div`
  width: ${p => p.width ? p.width : ""};
  height: ${p => p.height ? p.height : ""};
`;

export const Input = styled.input`
  background-color: ${colors.input_bg};
  border-radius: 6px;
  border: ${p => p.error ? "2px solid #ff5d55" : `2px solid ${colors.input_border}`};
  color: ${colors.text};
  font-size: 16px;
  font-weight: 600;
  outline: none;
  height: 48px;
  padding: 0 62px 0 10px;
  width: 464px;
  transition: .2s ease-out;

  &:focus {
    border: ${p => p.error ? "2px solid #ff5d55" : `2px solid ${colors.input_border_focus}`};
    box-shadow: 0 0 0 6px #f0f7ff;
  }

  &::placeholder {
    color: ${colors.input_placeholder};
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${colors.primary_btn_border};
  border-radius: 8px;
  background-color: red;
  color: ${colors.primary_btn_text};
  cursor: pointer;
  font-family: "Manrope",serif;
  font-size: 16px;
  font-weight: bold;
  min-width: 200px;
  outline: none;
  padding: 15px 0;
  transition: 0.1s ease-out;
  &:hover {
    background-color: red;
  }
  &:active {
    transform: ${p => !p.disabled
    ? "scale(0.95)"
    : "none"
  };
  }
  &:disabled {
    background-color: #e4e8f0;
    border-color: #e4e8f0;
  }
`;

export const Spinner = styled.div`
  margin: -3px auto;
  padding: 11px;
  font-size: 10px;
  animation: load8 .9s infinite linear;
  transform: translateZ(0);
  border: 3px solid rgba(255, 255, 255, 0.2);
  border-left: 3px solid #fff;
  border-radius: 68px;

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
