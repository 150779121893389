import styled from "styled-components";
import { colors } from "../../helpers/style/constants";

export const AppTextarea = styled.textarea`
  background-color: ${colors.input_bg};
  border-radius: 6px;
  border: 1px solid ${colors.input_border};
  color: ${colors.text};
  font-size: 15px;
  font-weight: 400;
  outline: none;
  height: ${p => p.height ? p.height : "120px"};
  width: auto !important;
  transition: .2s ease-out;
  padding: 10px;
  resize: none;

  &:focus {
    border: 1px solid ${colors.input_border_focus};
    box-shadow: 0 0 0 3px #f0f7ff;
  }

  &::placeholder {
    color: ${colors.input_placeholder};
  }
`;
