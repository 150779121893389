import moment from "moment";
import "moment/min/locales";

export const humanizeDate = ({ language }, timestamp) => {
  moment.locale(language);

  const now = Date.now();
  const diff = (timestamp * 1000) - now;
  return moment.duration(diff).humanize(true);
}

export const humanizeZuluDate = ({ language }, zulu) => {
  moment.locale(language);

const dateTime = new Date(zulu);
const timestamp =  Math.floor(dateTime.getTime() / 1000);

const now = Date.now();
const diff = (timestamp * 1000) - now;
return moment.duration(diff).humanize(true);
}

export const durationFromNowInMs = (timestampInMs) => {
  const now = Date.now();
  const diff = now - timestampInMs;
  return moment.duration(diff)
}