const _setStatus = status => {
  switch (status) {
    case "finished":
      return "Complete";
    case "failed":
      return "Failed";
    default:
      return "Running";
  }
}

const _sortArrayOfObject = array => {
  return array.sort((a,b) => (a.updatedAt < b.updatedAt) ? 1 : ((b.updatedAt < a.updatedAt) ? -1 : 0))
}

const _isEmptyObject = inputObject => {
  return Object.keys(inputObject).length === 0;
};

export const deployment = item => {
  const deployment = {
    application: item?.application,
    id: item?.id,
    version: item?.version,
    endedAt: item?.endedAt / 1000,
    duration: item?.duration,
    step: item?.step,
    status: _setStatus(item?.status),
    errors: _isEmptyObject(item?.errors) ? null : item?.errors.flat(),
    updatedAt: (item?.updatedAt || item?.createdAt) / 1000
  }
  return deployment;
}

export const deployments = data => {
  const arrayOfResults = [];
  data.forEach(item => {
    arrayOfResults.push(deployment(item));
  });

  return _sortArrayOfObject(arrayOfResults);
}
