import {useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { KeycloakContext } from "../../auth/AuthWrapper";
import { EmptyState } from "../../components/EmptyState";
import { Button } from "../../components/Button/Button";
import * as sc from "./style";

export default function NotFound() {
  const history = useHistory();
  const [state] = useContext(KeycloakContext);
  const { t } = useTranslation();

  const [errorCode, setErrorCode] = useState("");

  useEffect(() => {
    // Retrieve the error code
    const errorCode = window.location.pathname.split("/")[3];
    setErrorCode(errorCode);
  }, []);

  const handleRedirect = () => {
    history.push({ pathname: "/apps" });
  }

  const handleRedirectToMarjoryHomePage = () => {
    if (state.baseName === "marjory") {
      return window.location.href = "https://operation.module.marjory.io";
    }
    return window.location.href = `https://${state.baseName}.module.marjory.io`;
  }

  if (errorCode === "403") {
    return (
      <sc.Wrapper>
        <EmptyState 
          icon="⛔"
          title={t("403_title")}
          message={t("403_message")}
        />
        <sc.ButtonContainer>
          <Button
            primary={true}
            label={t("403_button")}
            onClick={() => handleRedirectToMarjoryHomePage()}
          />
        </sc.ButtonContainer>
      </sc.Wrapper>
    )
  }

  return (
    <sc.Wrapper>
      <EmptyState 
        icon="🛠️"
        title={t("500_title")}
          message={t("500_message")}
      />
      <sc.ButtonContainer>
        <Button
          primary={true}
          label={t("500_button")}
          onClick={() => handleRedirect()}
        />
      </sc.ButtonContainer>
    </sc.Wrapper>
  )
}