import styled from "styled-components";
import { colors } from '../../helpers/style/constants';

export const Spacer = styled.div`
  height: ${p => p.height};
`;

export const SlidingPaneWrapper = styled.div`
  padding: 0 10px 100px;
`;

export const Header = styled.div``;

export const Main = styled.div``;

export const SavingContainer = styled.div`
  background-color: #fff;
  margin-left: -42px;
  padding-left: 42px;
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 80px;
  bottom: 0; 
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 300px;
`;

export const Cancel = styled.button`
  background-color: transparent;
  border: none;
  color: ${colors.dark_grey};
  font-size: 15px;
  font-weight: 500;
  transition: .1s ease-out;

  &:hover {
    color: ${colors.marjory_blue};
  }
`;

export const Spinner = styled.div`
  margin: -3px auto;
  padding: 11px;
  font-size: 10px;
  animation: load8 .9s infinite linear;
  transform: translateZ(0);
  border: 1px solid ${colors.dark_grey};
  border-left: 1px solid #fff;
  border-radius: 68px;

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
