import { useContext, useEffect, useState } from "react";
import { KeycloakContext } from "../../auth/AuthWrapper";
import { useTranslation } from "react-i18next";
import { AppSelect } from "../AppsSelect";
import { Calendar, Download, Lock, Settings, Shield, Trash, Users, Zap } from "react-feather";
import { CreateVersionModal } from "../Modal/CreateVersionModal";
import { getCoreUrl } from "../../helpers/getCoreUrl";
import * as sc from "./style";

export const AppDetails = ({ appWithVersion, zipUrl, handleChangeVersion, status, updatedAt, actionsCount, eventsCount, defaultVersion, versions, appId, onOpenDeleteAppModal, deploymentInProgress }) => {
  const { t } = useTranslation();
  const [keycloak] = useContext(KeycloakContext);
  const [zipFile, setZipFile] = useState("");

  useEffect(() => {
    const env = getCoreUrl(window.location.href);
    setZipFile(`https://factory-${env}-version-zip.s3.amazonaws.com/${zipUrl}`);
  }, []);

  return (
    <sc.Wrapper>
      <sc.Container>
        {_canCreateNewVersion(keycloak.userRoles) &&
          <CreateVersionModal versions={versions} appId={appId} isDisabled={deploymentInProgress}/>
        }
        <sc.Spacer height="24px" />
        {
          versions.length ?
            <sc.Info>
              <AppSelect
                appWithVersion={appWithVersion}
                versions={versions}
                selected={defaultVersion}
                handleChange={handleChangeVersion}
              />
            </sc.Info>
            : <></>
        }
        <sc.Info>
          <sc.LeftContainer>
            <Shield />
            <sc.Text>
              {t("visibility")} :
            </sc.Text>
          </sc.LeftContainer>
          <sc.Status status={status}>
            {status}
          </sc.Status>
        </sc.Info>
        <sc.Info>
          <sc.LeftContainer>
            <Calendar />
            <sc.Text>
              {t("updated_at")} :
            </sc.Text>
          </sc.LeftContainer>
          <sc.Content>
            {updatedAt}
          </sc.Content>
        </sc.Info>
        <sc.Divider />
        <sc.Info>
          <sc.LeftContainer>
            <Settings />
            <sc.Text>
              {t("actions")} :
            </sc.Text>
          </sc.LeftContainer>
          <sc.Content>
            {actionsCount}
          </sc.Content>
        </sc.Info>
        <sc.Info>
          <sc.LeftContainer>
            <Zap />
            <sc.Text>
              {t("events")} :
            </sc.Text>
          </sc.LeftContainer>
          <sc.Content>
            {eventsCount}
          </sc.Content>
        </sc.Info>
        {(status !== "draft") && (
          <>
            <sc.Divider />
            <sc.Info>
              <sc.LeftContainer>
                <Download />
                <sc.Link href={zipFile}>
                  Download ZIP file
                </sc.Link>
              </sc.LeftContainer>
            </sc.Info>
          </>
        )}
      </sc.Container>
    </sc.Wrapper>
  )
}

const _canCreateNewVersion = roles => {
  return roles.includes("services-create");
}
