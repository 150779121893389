import { useState, useEffect, useContext } from "react";
import { KeycloakContext } from "../../../../../auth/AuthWrapper";
import { useHistory } from "react-router-dom";
import * as service from "./service";
import slugify from 'slugify';
import View from "./View";

export const Step2 = ({ closeModal, globalStepValues }) => {
  let editor = "";
  const history = useHistory();
  const [state] = useContext(KeycloakContext);
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [slugName, setSlugName] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  // NOTE Store in slugName into component state
  useEffect(() => {
    if (globalStepValues[0]?.name) {
      const slugName = slugify(globalStepValues[0].name.toLowerCase());
      setSlugName(slugName);
    }
  }, [globalStepValues]);

  // NOTE Store app icon into component state
  useEffect(() => {
    if (!image) return setPreview(null);

    const objectUrl = URL.createObjectURL(image);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [image]);

  // NOTE Triggered when user selects a file from the computer
  const handleSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      return setImage(null);
    }

    const file = e.target.files[0];

    if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
      setImage(file);
    } else {
      setPreview(null);
    }
  };

  // NOTE Redirect user to the app page
  const handleRedirect = () => {
    setTimeout(() => {
      closeModal();
      history.push({ pathname: `/apps/${slugName}` });
    }, 500);
  }

  // NOTE Upload icon and redirect user
  const handleUpload = async () => {
    setIsUploading(true);
    if (setEditorRef) {
      const canvasScaled = editor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();

      const id = globalStepValues[0]?.id;
      const token = state.keycloak.token;

      await service.createIcon(id, croppedImg, token);
      setIsUploading(false);
      handleRedirect();
    }
  }

  const setEditorRef = ed => editor = ed;

  const params = {
    slugName,
    preview,
    handleSelectFile,
    setEditorRef,
    handleRedirect,
    handleUpload,
    isUploading
  };

  return <View {...params} />
}
