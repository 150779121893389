import styled from "styled-components";

export const TagContainer = styled.label`
  width: 80px;
  height: ${p => p.label === "in review"
    ? "28px"
    : p.label === "module tag"
      ? "28px"
      : "24px"
  };
  color: ${p => 
    p.label !== p.selected 
      ? "#ABADBB"
    : p.label === "private"
      ? "#ff5605"
    : p.label === "public"
      ? "#057bff"
    : p.label === "group"
      ? "#39CB90"
      : "#79869e"
  };
  background-color: ${p => 
    p.label !== p.selected 
      ? `#ffffff`
    : p.label === "private"
      ? "#fff1eb"
    : p.label === "public"
      ? "#f0f7ff"
    : p.label === "group"
      ? "#DFFBEC"
      : "#eef2f8"
  };
  padding: 4px 10px;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  display: flex;
  text-transform: ${p => p.label === "module tag"
    ? "capitalize"
    : "uppercase"
  };
  font-size: ${p => p.label === "module tag"
    ? "16px"
    : "12px"
  };
  border-radius: 5px;
  border: ${p => 
    p.selected !== p.label ? 
      "2px solid #E4E8F0" 
      : "2px solid white"
  };
  svg {
    width: 20px;
  }
  transition: .1s linear;
  &:hover {
    border: 2px solid #027aff;
    box-shadow: 0 0 0 5px #f0f7ff;
  }
  span {
    margin-left: 10px;
  }
  cursor: pointer;
  margin-right: 10px;
`;


export const Form = styled.form`
  display: flex;
  justify-content: space-between;
`;

export const Input = styled.input`
  display: none;
`;

export const Text = styled.p`
  text-transform: capitalize;
  margin: 0 0 0 5px;
`
