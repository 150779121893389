import { useTranslation } from "react-i18next";
import Spinner from "../../components/Loader/Spinner";
import { EmptyState } from "../../components/EmptyState";
import { MainWrapper, MainContainer } from "../../helpers/style/MainWrapper.style";
import {DeploymentsList } from "../../components/List/DeploymentList";
import * as sc from "./style";

export default function View({ isLoading, deployments, retryDeployment, isRetrying }) {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <MainWrapper>
        <Spinner />
      </MainWrapper>
    );
  }

  // NOTE if no deployments exist display placeholder
  if (!deployments?.length) {
    return (
      <MainWrapper>
        <MainContainer>
          <EmptyState 
            icon="⚡" 
            title="No deployment found" 
            message="Deployments of your applications will appear on this page"
          />
        </MainContainer>
      </MainWrapper>
    );
  }

  return (
    <>
      <MainWrapper>
        <MainContainer>
          {deployments.length > 0 && (
            <>
            <sc.TopContainer>
              <sc.H2>{t("deploymentWithCount", { count: deployments.length })}</sc.H2>
            </sc.TopContainer>
              <DeploymentsList items={deployments} retryDeployment={retryDeployment} isRetrying={isRetrying} />
            </>
          )}
        </MainContainer>
      </MainWrapper>
    </>
  );
}