import styled from "styled-components";
import { colors, hexagon } from "../../../helpers/style/constants";
import AvatarEditor from "react-avatar-editor";

export const ModalWrapper = styled.div`
  width: 510px;
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  width: 530px;
  margin: 30px 0 20px 0;
  display: grid;
`;

export const Title = styled.h2`
display: flex;
margin-bottom:5px;
font-size: 20px;
color: ${colors.text};
`;

export const SubTitle = styled.h3`
display: flex;
font-size: 17px;
color:${colors.secondary_text};
margin-top:0px;
`;

export const H2 = styled.h2`
  font-size: 18px;
  color: ${colors.text};
  margin-bottom: 24px;
`;

export const Previous = styled.button`
  background-color: transparent;
  border: none;
  color: #78859d;
  font-size: 16px;
  font-weight: bold;
  transition: .2s ease-out;

  &:hover {
    color: #020253;
  }
`;

export const BtnContainer = styled.div`
  display: flex;
  justify-content: ${p => p.right ? "flex-end" : "space-between"};
  margin-top: 50px;
`;

export const Spacer = styled.div`
  width: ${p => p.width ? p.width : ""};
  height: ${p => p.height ? p.height : ""};
`;

export const Error = styled.p`
  color: #e45655;
  font-weight: 600;
  font-size: 17px;
  margin: 3px 0 -34px;
`;

export const LengthCounterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 18px;
`;

export const LengthCounter = styled.div`
  margin-top: 16px;
  position: absolute;
  font-weight: 700;
  float: right;
`;

export const Spinner = styled.div`
  padding: ${p => p.large ?  "25px" : "9px"};
  font-size: 10px;
  animation: load8 .9s infinite linear;
  transform: translateZ(0);
  border: 2px solid rgba(255, 255, 255, .8);
  border-left: 2px solid #020253;
  border-radius: 68px;
  width: fit-content;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
`;

export const CenteringImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 24px;
`;

export const AppInitial = styled.span`
  font-size: 36px;
  font-weight: 500;
`;

export const ImageBorderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  clip-path: ${hexagon};
  background-color: #e4e8f0;
  width: 151px;
  height: 151px;
`;

export const ImageContainer = styled(AvatarEditor)`
  clip-path: ${hexagon};
  width: 147px;
  height: 147px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImageContent = styled.div`
  clip-path: ${hexagon};
  width: 147px;
  height: 147px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #161c39;

  & > svg {
    width: 32px;
    height: 32px;
  }
`;

export const UploadButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const UploadButton = styled.label`
  margin: 0 10px;
  background-color: ${p => p.preview ? "#fff" : "#020253"};
  border: 1px solid #020253;
  border-radius: 6px;
  color: ${p => p.preview ? "#020253" : "#fff"};
  cursor: ${p => p.isLoading ? 'auto' : 'pointer'};
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  max-width: inherit;
  outline: 0;
  overflow: hidden;
  padding: 15px 40px;
  position: relative;
  text-decoration: none;
  text-overflow: ellipsis;
  width: 130px;
  white-space: nowrap;
  -webkit-tap-highlight-color: transparent;
  text-align: center;
`;

export const InputFile = styled.input`
  display: none;
`;

export const Skip = styled.button`
  background-color: transparent;
  border: none;
  color: #78859d;
  font-size: 16px;
  font-weight: bold;
  -webkit-transition: .2s ease-out;
  transition: .2s ease-out;

  &:hover {
    color: #020253;
  }
`;
