export const apps = ({ results }) => {
  const apps = [];

  results.forEach((item) => {
    const application = {
      application_id: item.id,
      application_name: capitalize(item.display.name),
      application_slug: item.slug,
      application_category: null,
      application_icon_url: item.display.icon,
      application_status: item.visibility,
      application_versions_count: item.stats.totalVersions,
      application_installations_count: item.stats.totalInstallations,
      application_updated_at: null,
    };
    apps.push(application);
  });

  return apps;
}

export const legacy_apps = data => {
  const arrayOfResults = [];
  data.forEach((item) => {
    const application = {
      application_id: item.id,
      application_name: capitalize(item.name),
      application_slug: item.slug,
      application_category: item.category,
      application_icon_url: item.icon,
      application_status: item.status,
      application_versions_count: Object.keys(item.versions).length,
      application_installations_count: item.installationsCount ? item.installationsCount : 0,
      application_updated_at: item.updatedAt,
    };
    arrayOfResults.push(application);
  });
  const sortedApps = _sortArrayOfObject(arrayOfResults);
  
  return sortedApps;
}

function capitalize(str) {
  var firstLetter = str.slice(0,1);
  return firstLetter.toUpperCase() + str.substring(1);
}

const _sortArrayOfObject = (array) => {
  return array.sort((a,b) => (a.application_name > b.application_name) ? 1 : ((b.application_name > a.application_name) ? -1 : 0))
}