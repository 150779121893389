import { useTranslation } from "react-i18next";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import "../../../helpers/style/custom-modal.css";
import * as sc from "./style";

export default function LanguageModal({ open, onCloseModal }) {
  const { t, i18n } = useTranslation();

  const handleClick = (lang) => {
    switch (lang) {
      case "en":
        return i18n.changeLanguage("en");
      case "fr":
        return i18n.changeLanguage("fr");
      case "es":
        return i18n.changeLanguage("es");
      case "de":
        return i18n.changeLanguage("de");
      default:
        return i18n.changeLanguage("en");
    }
  }

  const isSelected = (lang) => {
    return lang === i18n.language;
  }

  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      center
      classNames={{
        overlay: "custom-overlay",
        modal: "custom-modal"
      }}
    >
      <sc.H2>{t("choose_language")}</sc.H2>
      <sc.ModalWrapper>
        <sc.Button lang="en" selected={isSelected("en")} onClick={() => handleClick("en")}>🇬🇧 English</sc.Button>
        <sc.Button lang="fr" selected={isSelected("fr")} onClick={() => handleClick("fr")}>🇫🇷 Français</sc.Button>
        <sc.Button lang="es" selected={isSelected("es")} onClick={() => handleClick("es")}>🇪🇸 Español</sc.Button>
        <sc.Button lang="de" selected={isSelected("de")} onClick={() => handleClick("de")}>🇩🇪 Deutsch</sc.Button>
      </sc.ModalWrapper>
    </Modal>
  )
}