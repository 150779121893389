import { useState } from "react";
import * as sc from "./style";
import SlidingPane from "react-sliding-pane";
import { PanelHeader } from "./components/PanelHeader";
import { PanelMain } from "./components/PanelMain";
import { Button } from "../Button/Button";
import "react-sliding-pane/dist/react-sliding-pane.css";
import "./style.css";

export const SlidingPanel = ({ openSlidePanel, closeSlidePanel, handleClickSlidePanel, selectedInputs, handleSaveNewInputs, isSaving }) => {
  const [header, setHeader] = useState({
    id: selectedInputs.id,
    name: selectedInputs.name,
    description: selectedInputs.description,
    usage: selectedInputs.usage,
    type: selectedInputs.type,
    isCrypted: selectedInputs.isCrypted
  });

  const [inputsList, setInputsList] = useState(selectedInputs.type === "events" ? selectedInputs.payload : selectedInputs.inputs);

  const handleChangeMainDescription = e => {
    setHeader({...header, description: e.target.value });
  };

  const handleChange = event => {
    const type = event.target.attributes["type"].value;
    const path = event.target.attributes["path"].value.split(",");

    const element = path.reduce(
      (previousValue, currentValue) => previousValue[currentValue],
      inputsList
    );

    element[type] = event.target.value;
    setInputsList([...inputsList]);
  };

  return (
    <SlidingPane
      isOpen={openSlidePanel}
      onRequestClose={!isSaving && handleClickSlidePanel}
      className="customPanel"
      overlayClassName="panelOverlay"
      hideHeader={true}
      width="760px"
    >
    <sc.SlidingPaneWrapper>
      <sc.Spacer height="20px" />
      <sc.Header>
        <PanelHeader data={header} handleChangeMainDescription={handleChangeMainDescription} />
      </sc.Header>
      <sc.Spacer height="60px" />
      <sc.Main>
        <PanelMain header={header} data={inputsList} handleChange={handleChange} />
      </sc.Main>
      <sc.SavingContainer>
        <sc.ButtonsContainer>
          <Button 
            label={isSaving ? <sc.Spinner/> : "Save"} 
            onClick={() => handleSaveNewInputs(header, inputsList)} 
            small 
          />
          <sc.Cancel onClick={closeSlidePanel}>Cancel</sc.Cancel>
        </sc.ButtonsContainer>
      </sc.SavingContainer>
    </sc.SlidingPaneWrapper>
  </SlidingPane>
  )
}