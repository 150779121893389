import styled from "styled-components";
import { colors } from '../../helpers/style/constants';

export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const H2 = styled.h2`
  margin: 30px 0;
  font-size: 24px;
  font-weight: 600;
  color: ${colors.dark_blue};
`;
export const ButtonContainer = styled.div`
  
`;
export const Loader = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  text-align: center;
  margin: 24px auto;
  width: 0px;
  padding: 9px;
  font-size: 10px;
  animation: load8 .9s infinite linear;
  transform: translateZ(0);
  border: 3px solid rgba(255, 255, 255, 0.2);
  border-left: 3px solid #020253;
  border-radius: 68px;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;
