import * as sc from "./Swap.style";

import { useContext, useEffect, useRef, useState } from "react";

import AutomationPlatformIcon from "../../images/hover-core.svg";
import AutomationPlatformIconHover from "../../images/default-core.svg"
import { KeycloakContext } from "../../auth/AuthWrapper";
import appStoreIcon from "../../images/appStoreIcon.svg";
import appStoreIconHover from "../../images/appStoreIcon-hover.svg";
import documentationIcon from "../../images/documentation.svg";
import documentationIconHover from "../../images/documentation-hover.svg";
import { getPortalUrl } from "../../helpers/getPortalUrl";
import { getStoreUrl } from "../../helpers/getStoreUrl";
import portal from "../../images/app-builder.svg";
import switchIcon from "../../images/default-switch.svg";
import switchIconHover from "../../images/hover-switch.svg";
import { useOutsideClick } from "../../hooks/useOutsideClick";
const DOCUMENTATION_URL = `https://${ process.env.REACT_APP_STAGE !== 'prod.' && process.env.REACT_APP_STAGE !== 'undefined' ?  process.env.REACT_APP_STAGE : ''}docs.marjory.io/`;
export const Swap = () => {
  const [state] = useContext(KeycloakContext);

  const [isOpen, setIsOpen] = useState(false);
  const [isSwitchHover, setIsSwitchHover] = useState(false);
  const [isLinkHover, setIsLinkHover] = useState(false);
  const [isStoreLinkHover, setIsStoreLinkHover] = useState(false);
  const [isDocumentationLinkHover, setIsDocumentationLinkHover] = useState(false);

  const handleClick = () => setIsOpen(!isOpen);
  const handleSwitchHover = () => setIsSwitchHover(!isSwitchHover);
  const handleLinkHover = () => setIsLinkHover(!isLinkHover);
  const handleStoreLinkHover = () => setIsStoreLinkHover(!isStoreLinkHover);

  const handleDocumentationLinkHover = () => setIsDocumentationLinkHover(!isDocumentationLinkHover);

  const buttonRef = useRef(null);
  const buttonClickedOutside = useOutsideClick(buttonRef);


  useEffect(() => {
    if (isOpen && buttonClickedOutside) {
      handleClick();
    }
  }, [buttonClickedOutside]);


  return (
    <>
      <sc.Wrapper>
        <sc.DropdownButton
          onClick={handleClick}
          onMouseEnter={handleSwitchHover}
          onMouseLeave={handleSwitchHover}
          isOpen={isOpen}
        >
          {
            isOpen
              ? <sc.DropdownIcon src={switchIconHover} alt="switch" />
              : isSwitchHover
                ? <sc.DropdownIcon src={switchIconHover} alt="switch" />
                : <sc.DropdownIcon src={switchIcon} alt="switch" />
          }
        </sc.DropdownButton>
        <sc.PortalIcon src={portal} alt="portal" />
      </sc.Wrapper>
      <sc.DropdownMenu visible={isOpen} ref={buttonRef}>
        <sc.Menu visible={isOpen}>
          <sc.MenuTitle>
            Go to
          </sc.MenuTitle>
          <sc.List>
            <li>
              <sc.ListItemLink
                href={getPortalUrl(window.location.href)}
                onMouseEnter={handleLinkHover}
                onMouseLeave={handleLinkHover}
              >
                <sc.LinkContainer>
                  {
                    isLinkHover
                      ? <sc.LinkIcon src={AutomationPlatformIcon} alt="AutomationPlatformIcon" />
                      : <sc.LinkIcon src={AutomationPlatformIconHover} alt="AutomationPlatformIconHover" />
                  }
                  <sc.TextContainer>
                    <sc.LinkTitle>Automation platform</sc.LinkTitle>
                    <sc.LinkBaseline>Build, connect and run your processes</sc.LinkBaseline>
                  </sc.TextContainer>
                </sc.LinkContainer>
              </sc.ListItemLink>
            </li>
            <li>
              <sc.ListItemLink
                href={getStoreUrl(window.location.href)}
                onMouseEnter={handleStoreLinkHover}
                onMouseLeave={handleStoreLinkHover}
                >
                <sc.LinkContainer>
                  {
                  !isStoreLinkHover
                    ? <sc.LinkIcon src={appStoreIcon} alt="appStoreIcon" />
                    : <sc.LinkIcon src={appStoreIconHover} alt="appStoreIconHover" />
                  }
                  <sc.TextContainer>
                    <sc.LinkTitle>App Store</sc.LinkTitle>
                    <sc.LinkBaseline>Access to the app catalog</sc.LinkBaseline>
                  </sc.TextContainer>
                </sc.LinkContainer>
              </sc.ListItemLink>
            </li>
            <sc.Divider/>
            <li>
              <sc.ListItemLink
                  href={DOCUMENTATION_URL}
                  onMouseEnter={handleDocumentationLinkHover}
                  onMouseLeave={handleDocumentationLinkHover}
                  target="_blank">
                <sc.LinkContainer>
                  {
                    !isDocumentationLinkHover
                        ? <sc.LinkIcon src={documentationIcon} alt="documentationIcon" />
                        : <sc.LinkIcon src={documentationIconHover} alt="documentationIconHover" />
                  }
                  <sc.TextContainer>
                    <sc.LinkTitle>Documentation</sc.LinkTitle>
                    <sc.LinkBaseline>Access to product documentation</sc.LinkBaseline>
                  </sc.TextContainer>
                </sc.LinkContainer>
              </sc.ListItemLink>
            </li>
          </sc.List>
        </sc.Menu>
      </sc.DropdownMenu>
    </>
  )
}
