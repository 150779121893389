import styled from "styled-components";
import { colors } from "../../../helpers/style/constants";

export const ModalWrapper = styled.div`
  width: 400px;
  margin: 30px 0;
  display: flex;
  flex-direction: column;
`;

export const H2 = styled.h2`
  font-size: 28px;
`;

export const Button = styled.button`
  background-color: ${colors.secondary_btn};
  border: ${p => p.selected ? `1px solid ${colors.secondary_btn_border}` : `1px solid ${colors.card_border}`};
  border-radius: 12px;
  padding: 20px 0;
  margin: 10px 0;
  color: ${colors.secondary_btn_text};
  font-size: 18px;
  font-weight: 700;
  transition: 0.2s linear;
  &:hover {
    box-shadow: ${colors.shadow};
  }
`;