import { useState } from "react";
import View from "./View";

export const Step1 = ({ selectVersionType, values, onValueChange, onNext, globalStepValues, displayedStep, maxStep, ...props}) => {
  const [versionType, setVersionType] = useState(values?.versionType || "");
  
  const handleChangeVersionType = (e) => {
    setVersionType(e.target.value);
  };

  const handleClickNext = () => {
    onValueChange({ versionType });
    selectVersionType(versionType)
    onNext();
  };

  /**
   * NOTE Rendering component
   */
  
  const params = { versionType, handleClick: handleClickNext, handleChangeVersionType, displayedStep, maxStep };
  return <View {...params} />
}