import { useState, cloneElement } from "react";
import Button from "../Button/Button";
import Modal from "react-responsive-modal";

/**
 * @param {string} title 
 * Title of the modal
 * @param {string} openModalTitle 
 * Title of the button openning the modal
 * @param {function} endMultiStep
 * Is called by the Modal on steps end, with all the values of the different step in params
 * 
 * @return {function} onNext()   
 * Allow the child to tell the parent to go to the next step 
 * @return {function} onPrevious()   
 * Allow the child to tell the parent to go to the previous step
 * @return {function} onValueChange(value)   
 * Allow the child to send his values to be stored by the parent
 * @return {function} endMultiStep()   
 * Return integrality of values for all steps
 * @return {function} closeModal()   
 * Close modal and set current step to first
 * @return {object} values
 * The child receives back (if it exist) the value he previously sent to
 * @return {object} globalStepValues
 * The child receives all the values stored from all the steps
 * 
 */

export default function MultiStepModal({ children, title, isDisabled, openModalTitle, endMultiStep }) {
  const [open, setOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [stepValues, setStepValues] = useState([]);

  const setNewValueForStep = value => {
    // Here we clone the values without reference, 
    // then we edit, and set new values in state
    // We finished by moving to the next step
    const localValues = Object.assign({}, stepValues);
    localValues[currentStep] = value;
    setStepValues(localValues);
  }

  const nextStep = () => {
    if(currentStep + 1 < children.length) {
      setCurrentStep(currentStep + 1);
    }
  }
  
  const previousStep = () => {
    if(currentStep - 1 >= 0) {
      setCurrentStep(currentStep - 1);
    }
  }

  const handleStepDisplay = () => {
    // Return the good child according to the currentStep ID, with onNext and onValueChange functions and possible value from before
    return cloneElement(children[currentStep], 
      {
        onNext:() => nextStep(), 
        onPrevious:() => previousStep(),
        onValueChange:(value) => setNewValueForStep(value), 
        endMultiStep: () => endMultiStep(stepValues),
        closeModal: () => handleCloseModal(),
        values:(stepValues[currentStep] || null) ,
        displayedStep: currentStep + 1,
        maxStep: children.length,
        globalStepValues : stepValues
      });
  }

  // NOTE Close modal
  const handleCloseModal = () => {
    setOpen(false);
    setStepValues([]);
    setTimeout(() => {
      setCurrentStep(0);
    }, 300);
  }

  return (
    children &&
    <>
      <Modal open={open} 
        center={true}
        onClose={handleCloseModal}
        showCloseIcon={true}
        closeOnOverlayClick={false}
        classNames={{
          overlay: "custom-overlay",
          modal: "custom-modal"
        }}
      >
        {/* Display current child based on currentStep id */}
        {handleStepDisplay()}
      </Modal>
      <Button
        label={openModalTitle ? openModalTitle : "Open Modal"}
        primary={true}
        block={true}
        small={true}
        onClick={()=>setOpen(true)}
        disabled={isDisabled}
      />
    </>
  );
}
