import { Input } from "../../../../Input/Input";
import { Button } from "../../../../Button/Button";
import { useTranslation } from "react-i18next";
import * as sc from "../../style";

export default function View({ name, handleChangeName, inputError, isLoading, handleCreateApp }) {
  const { t } = useTranslation();

  return (
    <sc.Container>
      <sc.Title>{t("create_new_app")}</sc.Title>
      <sc.H2>{t("name")}</sc.H2>
      <sc.LengthCounterWrapper>
        <sc.LengthCounter>{32-name.length}</sc.LengthCounter>
      </sc.LengthCounterWrapper>
      <Input
        isRequired
        onChange={handleChangeName}
        placeholder={t("app_name_placeholder")}
        value={name}
        type="text"
      />
      {inputError && <sc.Error>{inputError}</sc.Error>}
      <sc.Spacer height="24px" />
      <sc.BtnContainer right={true}>
        <Button
          label={isLoading ? <sc.Spinner /> : t("create_application")}
          onClick={() => handleCreateApp()}
          small={true}
          disabled={!name}
        />
      </sc.BtnContainer>   
    </sc.Container>
  )
}