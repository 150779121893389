import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

* {
  font-family: 'Manrope', sans-serif;
}
body {
  background-color: #fff;
  margin: 0;
}
button {
  cursor: pointer;
  outline: 0;
  -webkit-tap-highlight-color: transparent !important;
}
a {
  cursor: pointer;
  outline: 0;
  -webkit-tap-highlight-color: transparent !important;
}
input::-moz-focus-inner, button::-moz-focus-inner { 
  border: 0; 
}
`;
