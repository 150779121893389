export const colors = {
  primary_btn: "#18187A",
  marjory_blue: "#18187A",
  primary_btn_text: "#fff",
  primary_btn_hover: "#2A357E",
  primary_btn_border: "#18187A",
  light_grey: "#c7cdd1",
  very_very_light_grey: "#F8F8F8",
  dark_grey: "#707070",
  dark_blue: "#020827",
  secondary_btn: "#fff",
  secondary_btn_text: "#18187A",
  secondary_btn_hover: "#fff",
  secondary_btn_border: "#18187A",
  borderless_btn: "#fff",
  borderless_btn_hover: "#f7f6f6",
  actions_list_border: "#d6d6d6",
  action_summary: "#929292",
  card_border: "#d6d6d6",
  shadow: "0 0 13px 2px #00000017",
  module_logo: "#18187A",
  module_logo_border: "#18187A",
  module_logo_letter: "#fff",
  card_title: "#000",
  card_description: "#929292",
  card_sub_info: "#aaa",
  card_plus_icon: "#fff",
  card_plus_bg: "#18187A",
  dropdown_border: "#d6d6d6",
  dropdown_option_hover: "#f7f6f6",
  icon: "#808ca3",
  icon_border: "#ebebeb",
  icon_bg: "#fff",
  input_bg: "#fff",
  input_placeholder: "#707070",
  input_border: "#c7cdd1",
  input_border_focus: "#18187A",
  link: "#020827",
  list_border: "#c7cdd1",
  secondary_text: "#707070",
  list_actions_count: "#707070",
  nav_bg: "#ebf4ff",
  nav_label: "#fff",
  nav_label_active: "#18187A",
  nav_border: "#c7cdd1",
  nav_spacer: "#9890F7",
  very_light_purple: "#F0EFFF",
  light_purple: "#9890F7",
  tag_primary_label: "#fff",
  tag_primary_bg: "#c0c0c0",
  tag_secondary_label: "#000",
  tag_secondary_bg: "#d6d6d6",
  tag_tertiary_label: "#000",
  tag_tertiary_bg: "#f7f6f6",
  header_bg: "#fff",
  header_border: "#c7cdd1",
  marjory_logo_letter: "#fff",
  marjory_logo_bg: "#000",
  modal_border: "#efefef",
  text: "#020827",
  workflow_icon: "#18187A",
  web_service_icon: "#d63333",
  workflow_background: "#f8f8f8",
  web_service_background: "#fff1eb",
  new_input_border: "#c7cdd1",
  new_input_border_focus: "#18187A",
  deployment_tag_complete_label: "#39CB90",
  deployment_tag_complete_bg: "#DFFBEC",
  deployment_tag_failed_label: "#d63333",
  deployment_tag_failed_bg: "#ffebeb",
  deployment_tag_running_label: "#18187A",
  deployment_tag_running_bg: "#ebf4ff",
  blue: "#18187A",
  blue_light: "#ebf4ff",
  external_background: "#fffbe8",
  external_icon: "#fda500"
};

export const spaces = {
  space_lg: "20px",
  space_xxl: "40px"
};

export const width = {
  width_btn: "200px"
};

export const hexagon = "polygon(45% 1.33975%,46.5798% 0.60307%,48.26352% 0.15192%,50% 0%,51.73648% 0.15192%,53.4202% 0.60307%,55% 1.33975%,89.64102% 21.33975%,91.06889% 22.33956%,92.30146% 23.57212%,93.30127% 25%,94.03794% 26.5798%,94.48909% 28.26352%,94.64102% 30%,94.64102% 70%,94.48909% 71.73648%,94.03794% 73.4202%,93.30127% 75%,92.30146% 76.42788%,91.06889% 77.66044%,89.64102% 78.66025%,55% 98.66025%,53.4202% 99.39693%,51.73648% 99.84808%,50% 100%,48.26352% 99.84808%,46.5798% 99.39693%,45% 98.66025%,10.35898% 78.66025%,8.93111% 77.66044%,7.69854% 76.42788%,6.69873% 75%,5.96206% 73.4202%,5.51091% 71.73648%,5.35898% 70%,5.35898% 30%,5.51091% 28.26352%,5.96206% 26.5798%,6.69873% 25%,7.69854% 23.57212%,8.93111% 22.33956%,10.35898% 21.33975%)";
