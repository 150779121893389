import Tooltip from "react-simple-tooltip"
import { useTranslation } from "react-i18next";
import { humanizeZuluDate } from "../../helpers/humanizeDate";
import { GitBranch, BarChart2 } from "react-feather";
import { css } from "styled-components"
import { Logo } from "../Logo/Logo";
import { Tag } from "../Tag/Tag";
import * as sc from "./List.style";

export const List = ({ items, ...props }) => {
  const { t, i18n } = useTranslation();

  const statCounter = (item, type) => {
    if (type === "version") {
      const count = item.application_versions_count || 0;
      return t("versionWithCount", { count });
    } else {
      const count = item.application_installations_count || 0;
      return t("installationWithCount", { count });
    }
  }

  return (
    <sc.ListContainer>
      {items.map((item) => {
        return (
          <sc.ListItem key={item.application_id} hover={item.application_status}>
            <sc.Link
              link={item.application_status}
              to={`apps/${item.application_slug}`}
            >
              <sc.LeftBlock>
                <Logo
                  name={item.application_name}
                  url={item.application_icon_url}
                  size="medium"
                />
                <sc.ModuleName>
                  <p>
                    {item.application_name}
                  </p>
                </sc.ModuleName>
              </sc.LeftBlock>
            </sc.Link>
            <sc.MiddleBlock>
              <Tooltip
                content={statCounter(item, "version")}
                background="#161c39"
                fadeDuration={200}
                fontFamily="Manrope"
                fontSize="14px"
                padding={10}
                radius={6}
                customCss={css`
                  white-space: nowrap;
                `}
              >
                <sc.AppStat>
                  <GitBranch />{item.application_versions_count}
                </sc.AppStat>
              </Tooltip>
              <sc.VerticalSpacer />
              <Tooltip
                content={statCounter(item, "installation")}
                background="#161c39"
                fadeDuration={200}
                fontFamily="Manrope"
                fontSize="14px"
                padding={10}
                radius={6}
                customCss={css`white-space: nowrap;`}
              >
                <sc.AppStat>
                  <BarChart2 />{item.application_installations_count}
                </sc.AppStat>
              </Tooltip>
            </sc.MiddleBlock>
            <sc.RightBlock>
              <sc.RightBlockMargin>
                <Tag
                  label={item.application_status}
                />
              </sc.RightBlockMargin>
            </sc.RightBlock>
          </sc.ListItem>
        );
      })}
    </sc.ListContainer>
  );
};

export default List;
