import { colors, hexagon } from "../../helpers/style/constants";

import styled from "styled-components";

export const Overlay = styled.div`
  opacity: 0;
  z-index: 1000;
  background-color: red;
  transition: 0.2s ease-out;
  background: rgba(2, 2, 83, 0.6);
  color: #fff;
  cursor: pointer;
  width: 84px;
  height: 84px;
  display: flex;
  align-items: center;
  justify-content: center;
  clip-path: ${hexagon};
  &:hover {
    opacity: 1;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-weight: 500;
  clip-path: ${hexagon};
  background-color: ${p => p.url ? "#fff" : colors.module_logo};
  color: ${colors.module_logo_letter};
  width: ${p => p.size === "small"
    ? "46px"
    : p.size === "medium"
      ? "52px"
      : "84px"
  };
  height: ${p => p.size === "small"
    ? "46px"
    : p.size === "medium"
      ? "52px"
      : "84px"
  };
  font-size: ${p => p.size === "small"
    ? "22px"
    : p.size === "medium"
      ? "22px"
      : "36px"
  };

  &:hover ~ ${Overlay} {
    opacity: 1;
  }
`;

export const Img = styled.img`
  width: ${p => p.size === "small"
    ? "46px"
    : p.size === "medium"
      ? "52px"
      : "84px"
  };
  height: ${p => p.size === "small"
    ? "46px"
    : p.size === "medium"
      ? "52px"
      : "84px"
  };
  border-radius: 6px;
`;

export const LogoBorder = styled.div`
  clip-path: ${hexagon};
  width: ${p => p.size === "small"
    ? "50px"
    : p.size === "medium"
      ? "56px"
      : "88px"
  };
  height: ${p => p.size === "small"
    ? "50px"
    : p.size === "medium"
      ? "56px"
      : "88px"
  };
  background-color: #e4e8f0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;
