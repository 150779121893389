export const applicationById = (version, application, deployments) => {
  const applicationAllVersions = []
  const versionActions = [];
  const versionEvents = [];
  const versionContexts = [];

  if(version) {
    for (const obj in application?.data?.versions) {
      const result = {
        versionId:application.data.versions[obj],
        versionNumber: obj,
      }
      applicationAllVersions.push(result);
    }

    for(const action of version?.data?.actions) {
      const result = {
        id: action.id,
        name: action.name,
        description: action.description,
        usage: action.usage,
        inputs: action.inputs,
        type: "actions"
      }
      versionActions.push(result);
    }

    for(const event of version?.data?.events) {
      const result = {
        id: event.id,
        name: event.name,
        description: event.description,
        usage: event.usage,
        payload: event.payload,
        type: "events"
      }
      versionEvents.push(result);
    }

    for(const context of version?.data?.context) {
      const result = {
        id: context.id,
        name: context.name,
        description: context.description,
        inputs: context.inputs,
        type: "contexts",
        isCrypted: context.crypted
      }
      versionContexts.push(result);
    }
  }

  return {
    applicationId: application.data.id,
    applicationName: application.data.name,
    applicationOwner: application.data.tenantId || "OWNER-NAME",
    applicationIconUrl: application.data.icon || null,
    applicationDescription: application.data.description || "", 
    applicationCurrentVersion: version?.data?.id,
    applicationStatus: application.data.status, 
    applicationSubcategories: application.data.subcategories || [],
    applicationAllVersions,
    applicationUpdatedAt: version?.data?.deployment.updatedAt || version?.data?.deployment.createdAt,
    // applicationUpdatedAt: application.data.updatedAt || null,
    deployments: deployments || null,
    currentVersionZip: version?.data?.zipName || null,
    currentVersionActions: versionActions || [],
    currentVersionEvents: versionEvents || [],
    currentVersionContexts: versionContexts || [],
    currentVersionActionsCount: versionActions.length || 0,
    currentVersionEventsCount: versionEvents.length || 0,
    currentVersionDocumentation: version?.data?.documentationUrl || null,
    currentVersionVisibility: version?.data?.visibility || "draft",
  }
}
