import styled from "styled-components";

export const Tag = styled.span`
  border-radius: 5px;
  background-color: ${p => p.main ? "#ebf4ff" : "#eef2f8"};
  color: ${p => p.main ? "#027aff" : "#79869e"};
  font-size: 14px;
  font-weight: 700;
  justify-content: center;
  padding: 8px 10px;
  margin: 10px 10px 0 0;
  display: inline-block;
`;
