import styled from "styled-components";
import { colors } from '../../helpers/style/constants';

export const Input = styled.input`
  display: none;
`;

export const Container = styled.label`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colors.light_grey};
  border-radius: 6px;
  width: 140px;
  height: 140px;
  transition: .1s linear;
  &:hover {
    background: ${colors.very_very_light_grey};
    border: 1px solid ${colors.marjory_blue};
  }

  & > svg {
    width: 32px;
    height: 32px;
    stroke: ${colors.light_purple};
  }
`;

export const Spacer = styled.div`
  height: ${p => p.height ? `${p.height}` : ""};
`;
