import * as sc from '../List/DeploymentList/style';
import { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Calendar } from 'react-feather';
import { durationFromNowInMs, humanizeDate } from '../../helpers/humanizeDate';
import { DeploymentSteps } from '../DeploymentSteps';
import { DeploymentTag } from '../DeploymentTag';
import { getApplication } from '../../api/applications.api';
import { getdeployment } from '../../api/deployments.api';
import { useQuery } from 'react-query';
import { KeycloakContext } from '../../auth/AuthWrapper'
import { Logo } from '../Logo/Logo'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { DeploymentErrors } from '../DeploymentErrors';
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import "../../helpers/style/custom-modal.css"

const POLLING_INTERVAL_MS = 10000;
const MAX_POLLING_DURATION_MS = 3600000; // 1 hour

export const DeploymentItem = ({ deployment: initDeployment,  retryDeployment, isRetrying, }) => {
  const { t, i18n } = useTranslation();
  const [state] = useContext(KeycloakContext);
  const [deployment, setDeployment] = useState(initDeployment);

  const { data: application, isFetching } = useQuery(
    ['applications', deployment?.application],
    () =>
      getApplication({
        applicationId: deployment?.application,
        token: state.keycloak.token,
      }),
    { enabled: !!deployment?.application }
  );

  const { data: updatedDeployment } = useQuery(
    ["deployments", deployment?.id],
    () =>{
        return getdeployment({
        token: state.keycloak.token,
        deploymentId: deployment?.id})},
    {
        refetchInterval: POLLING_INTERVAL_MS,
        enabled: deployment?.status === "Running"
          && durationFromNowInMs(deployment?.updatedAt * 1000) < MAX_POLLING_DURATION_MS
  });

  useEffect(() => {
    if (!updatedDeployment) {
      return
    }
    setDeployment(updatedDeployment);
  }, [updatedDeployment]);

  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState({});

  // NOTE open the deployment errors modal
  const onOpenModal = item => {
    setOpenModal(true);
    setModalContent(item);
  }

    const onCloseModal = () => {
      setOpenModal(false);
      // setModalContent({});
    };

  if (deployment?.errors) {
    return (
      <>
      <sc.ListItem  onClick={() => onOpenModal(deployment)}>
        <sc.Link to={`/deployments`}>
          <sc.LeftBlock>
            <Logo
              name={application?.name}
              url={application?.icon}
              size="medium"
            />
            <sc.AppName>
            {application
              ? <p>{application?.name}</p>
              : <Skeleton height={'3vh'} width={'8vw'}/>
            }
              <sc.Version>
                {t("version")} {deployment?.version}
              </sc.Version>
            </sc.AppName>
          </sc.LeftBlock>
        </sc.Link>
        <sc.MiddleBlock>
          <sc.DeploymentDate>
            <Calendar />{humanizeDate(i18n, deployment?.updatedAt)}
          </sc.DeploymentDate>
        </sc.MiddleBlock>
        <sc.RightBlock>
          <sc.RightBlockMargin>
            <DeploymentSteps
              status={deployment?.status}
              step={deployment?.step}
            />
          </sc.RightBlockMargin>
          <sc.RightBlockMargin>
            <DeploymentTag label={deployment?.status} />
          </sc.RightBlockMargin>
        </sc.RightBlock>
      </sc.ListItem>
        <Modal
          open={openModal}
          onClose={onCloseModal}
          center
          classNames={{
            overlay: "custom-overlay",
            modal: "custom-modal"
          }}
        >
          <DeploymentErrors
            appName={modalContent.name}
            icon={modalContent.icon}
            deploymentId={modalContent.id}
            version={modalContent.version}
            errors={modalContent.errors}
            retryDeployment={retryDeployment}
            isLoading={isRetrying}
            onCloseModal={onCloseModal}
          />
        </Modal>
      </>
    )
  }

  if (deployment?.status === "Running") {
    return (
      <sc.ListItem >
        <sc.Link to={`/deployments`} >
          <sc.LeftBlock>
            <Logo
              name={application?.name}
              url={application?.icon}
              size="medium"
            />
            <sc.AppName>
            {application ? <p>{application?.name}</p> : <Skeleton height={'3vh'} width={'8vw'}/>}
              <sc.Version>
                {t("version")} {deployment?.version}
              </sc.Version>
            </sc.AppName>
          </sc.LeftBlock>
        </sc.Link>
        <sc.MiddleBlock>
          <sc.DeploymentDate>
            <Calendar />{humanizeDate(i18n, deployment?.updatedAt)}
          </sc.DeploymentDate>
        </sc.MiddleBlock>
        <sc.RightBlock>
          <sc.RightBlockMargin>
            <DeploymentSteps
              status={deployment?.status}
              step={deployment?.step}
            />
          </sc.RightBlockMargin>
          <sc.RightBlockMargin>
            <DeploymentTag label={deployment?.status} />
          </sc.RightBlockMargin>
        </sc.RightBlock>
      </sc.ListItem>
    )
  }

  return (
    <sc.ListItem>
      <sc.Link to={`/apps/${application?.slug}/v/${deployment?.version}`} >
        <sc.LeftBlock>
          <Logo
            name={application?.name}
            url={application?.icon}
            size="medium"
          />
          <sc.AppName>
          {application ? <p>{application?.name}</p> : <Skeleton height={'3vh'} width={'8vw'}/>}
            <sc.Version>
              {t("version")} {deployment?.version}
            </sc.Version>
          </sc.AppName>
        </sc.LeftBlock>
      </sc.Link>
      <sc.MiddleBlock>
        <sc.DeploymentDate>
          <Calendar />{humanizeDate(i18n, deployment?.updatedAt)}
        </sc.DeploymentDate>
      </sc.MiddleBlock>
      <sc.RightBlock>
        <sc.RightBlockMargin>
          <DeploymentSteps
            status={deployment?.status}
            step={deployment?.step}
          />
        </sc.RightBlockMargin>
        <sc.RightBlockMargin>
          <DeploymentTag label={deployment?.status} />
        </sc.RightBlockMargin>
      </sc.RightBlock>
    </sc.ListItem>
  )
};
