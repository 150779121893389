import {getUploadConfig} from "../../../../../helpers/getConfig";
import axios from "axios";
const API_URL = process.env.REACT_APP_FACTORY_API_URL;


export const postDeployment = async (token, globalStepValues, file, appId) => {
    try {

        const params = new FormData();
        const version = globalStepValues[1].nextVersion.replace("y", "0").replace("z", "0");
        const changelog = globalStepValues[2].changelog;
        const commit = globalStepValues[2].commit;
        const visibility = globalStepValues[2].visibility;

        params.append("applicationId", appId);
        params.append("version", version);
        params.append("changelog", changelog);
        params.append("comment", commit);
        params.append("visibility", visibility);
        params.append("zip", file);

        const config = getUploadConfig(token, params);
        await axios.post(`${API_URL}/deployments`, params, config);
        
    } catch(err) {
        console.error(err);
        throw(err);
    }    
}