import styled from "styled-components";
import { colors } from "../../helpers/style/constants";

export const TagContainer = styled.div`
  width: 80px;
  height: 24px;;
  color: ${p => p.label === "Complete"
      ? `${colors.deployment_tag_complete_label}`
    : p.label === "Failed"
      ? `${colors.deployment_tag_failed_label}`
      : `${colors.deployment_tag_running_label}`
  };
  background-color:  ${p => p.label === "Complete"
      ? `${colors.deployment_tag_complete_bg}`
    : p.label === "Failed"
      ? `${colors.deployment_tag_failed_bg}`
      : `${colors.deployment_tag_running_bg}`
  };
  padding: 4px 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  border-radius: 5px;
`;
