import styled from "styled-components";
import TextareaAutosize from "react-autosize-textarea";
import { colors } from '../../helpers/style/constants';

export const TextArea = styled(TextareaAutosize)`
  resize: none;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #e4e8f0;
  color: #161c39;
  font-size: 15px;
  font-weight: 400;
  outline: none;
  padding: 10px;
  transition: .2s ease-out;
  width: calc(100% - 24px);

  &:focus {
    border: 1px solid ${colors.light_grey};
    box-shadow: 0 0 0 3px #f0f7ff;
  }
`;
