import React from "react";
import { Eye, File, Shield, Users } from "react-feather";
import * as sc from "./Tag.style";

export const Tag = ({type, label, ...props}) => {
    if (["CUSTOMER", "TENANT", "private"].includes(label)) {
        return (
            <sc.TagContainer type={type} label={'private'}>
                <Shield/> <span>{'private'}</span>
            </sc.TagContainer>
        )
    }

    if (["PUBLIC", "public"].includes(label)) {
        return (
            <sc.TagContainer type={type} label={'public'}>
                <Eye/> <span>{'public'}</span>
            </sc.TagContainer>
        )
    }

    if (label === "group") {
        return (
            <sc.TagContainer type={type} label={label}>
                <Users/> <span>{label}</span>
            </sc.TagContainer>
        )
    }

    return (
        <sc.TagContainer type={type} label={label}>
            <File/> <span>{label}</span>
        </sc.TagContainer>
    )
};

Tag.propTypes = {};

Tag.defaultProps = {};
