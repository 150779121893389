import axios from "axios";
import { getConfig } from "../../helpers/getConfig";
import * as mapper from "./mapper";

const API_URL = process.env.REACT_APP_FACTORY_API_URL;

export const getApplicationByName = async (applicationName, token) => {
  try {
    const config = getConfig(token);
    const applicationResult = await axios.get(`${API_URL}/applications/${applicationName}`, config);
    
    const applicationId = applicationResult.data.id;
    const deployments = await axios.get(`${API_URL}/deployments?applicationId=${applicationId}`, config);
    const deploymentsFailedOrInProgress = _getDeploymentsFailedOrInProgress(deployments);

    if (applicationResult.data.status !== "draft") {
      const versionId = applicationResult.data.main;
      const mainVersion = await axios.get(`${API_URL}/versions/${versionId}`, config);

      return mapper.applicationById(mainVersion, applicationResult, deploymentsFailedOrInProgress);
    }

    return mapper.applicationById(null, applicationResult, deploymentsFailedOrInProgress);
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export const getApplicationByNameAndVersion = async (applicationName, versionNumber, token) => {
  try {
    const config = getConfig(token);
    const applicationResult = await axios.get(`${API_URL}/applications/${applicationName}`, config);
    let dataVersion = await axios.get(`${API_URL}/versions?applicationName=${applicationName}&version=${versionNumber}`, config);
    // TODO Promise all
    const applicationId = applicationResult.data.id;
    const deployments = await axios.get(`${API_URL}/deployments?applicationId=${applicationId}`, config);
    const deploymentsFailedOrInProgress = _getDeploymentsFailedOrInProgress(deployments);
    dataVersion = { data: dataVersion.data };

    return mapper.applicationById(dataVersion, applicationResult, deploymentsFailedOrInProgress);
    } catch (err) {
    console.error(err);
    throw err;
  }
}

export const updateApplicationInputs = async (versionId, payload, token) => {
  try {
    const config = getConfig(token);
    return await axios.post(`${API_URL}/versions/${versionId}`, payload, config);
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export const deleteDraftApplication = async (applicationId, token) => {
  try {
    const config = getConfig(token);
    return await axios.delete(`${API_URL}/applications/${applicationId}`, config);
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export const deleteVersionApplication = async (versionId, token) => {
  try {
    const config = getConfig(token);
    return await axios.delete(`${API_URL}/versions/${versionId}`, config);
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export const publishVersionApplication = async (versionId, token) => {
  try {
    const config = getConfig(token);
    return await axios.patch(`${API_URL}/versions/${versionId}/publish`, {}, config);
  } catch(err) {
    throw err;
  }
}

const _getDeploymentsFailedOrInProgress = ({ data }) => {
  const array = data.filter(item => item.status !== "finished");

  // Remove duplicated version
  return array.reduce((acc, current) => {
    const x = acc.find(item => item.version === current.version);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);
}

export const updateApplication = async (applicationId, newDescription, token) => {
  try {
    const config = getConfig(token);
    const body = {
      description: newDescription,
    };
  
    await axios.patch(`${API_URL}/applications/${applicationId}`, body, config);
  } catch (err) {
    console.error(err);
    throw err;
  }
}

/**
 * Updating a private module by upload a zip
 * @param {Guid}   moduleId  The module ID
 * @param {Guid}   moduleZip The zip of the module
 * @param {String} token     The keycloak token
 */
// export const postUpdatePrivateModule = async (moduleId, moduleZip, token) => {
//   try {
//     const params = new FormData();
//     params.append("moduleId", moduleId);
//     params.append("module", moduleZip);
//     const config = getUploadConfig(token, params);

//     await axios.post(`${MR_API_URL}/deploymentRequests`, params, config);

//     return;
//   } catch (err) {
//     console.error(err);
//     throw err;
//   }
// }

/**
 * Updating a public module by uploading a zip
 * @param {Guid}   moduleId  The module ID
 * @param {Guid}   moduleZip The zip of the module
 * @param {String} token     The keycloak token
 */
// export const postUpdatePublicModule = async (moduleId, moduleZip, token) => {
//   try {
//     const params = new FormData();
//     params.append("moduleId", moduleId);
//     params.append("module", moduleZip);
//     const config = getUploadConfig(token, params);

//     await axios.post(`${MR_API_URL}/publishRequests`, params, config);

//     return;
//   } catch (err) {
//     console.error(err);
//     throw err;
//   }
// }

/**
 * Submit a private module for publication
 * @param {Guid}   moduleId  The module ID
 * @param {String} token     The keycloak token
 */
// export const postSubmitModule = async (moduleId, token) => {
//   try {
//     const param = new FormData();
//     param.append("moduleId", moduleId);
//     const config = getUploadConfig(token, param);

//     await axios.post(`${MR_API_URL}/publishRequests`, param, config);

//     return;
//   } catch (err) {
//     console.error(err);
//     throw err;
//   }
// }

/**
 * Get the deployment status of a specific module
 * @param  {Guid}   moduleId  The module ID
 * @param  {String} token     The keycloak token
 * @return {Object}           The status and possibly the module deployment error
 */
// export const getDeploymentStatus = async (moduleId, token) => {
//   try {
//     const config = getConfig(token);
//     const res = await axios.get(`${MR_API_URL}/deploymentRequests?moduleId=${moduleId}`, config);

//     return {
//       status: res.data[0].status,
//       error: res.data[0].error || null
//     };
//   } catch (err) {
//     console.error(err);
//     throw err;
//   }
// }