import styled from "styled-components";
import { Link as a } from "react-router-dom";
import { colors } from "../../../helpers/style/constants";

export const ListContainer = styled.ul`
  list-style-type: none;
  font-weight: 700;
`;

export const Link = styled(a)`
  color: ${colors.link};
  cursor: pointer;
  text-decoration: none;
  display: flex;
  flex: 1.15;
  align-items: center;
`;

export const ListItem = styled.li`
  border-left: 1px solid ${colors.list_border};
  border-right: 1px solid ${colors.list_border};
  transition: 0.2s ease-out;
  padding: 10px 20px;
  display: flex;
  &:first-child{
    border-top: 1px solid ${colors.list_border};
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &:last-child{
    border-bottom: 1px solid ${colors.list_border};
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  &:not(first-child), &:not(last-child){
    border-bottom: 1px solid ${colors.list_border};
  }
  &:hover{
    background-color: ${colors.very_light_purple};
    cursor: pointer;
    
    p {
        color: ${colors.nav_label_active};
    }
  }
`;

export const LeftBlock = styled.div`
  display: contents;
`;

export const AppName = styled.div`
  text-transform: capitalize;
  margin-left: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;

  & > p {
    margin: 0;
    font-size: 16px;
    color: #020827;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const RightBlock = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
`;

export const Version = styled.div`
  font-size: 12px;
  color: ${colors.dark_grey};
  white-space: nowrap;
  font-weight: 400;
`;

export const RightBlockMargin = styled.div`
  margin-left: 60px;
`;

export const MiddleBlock = styled.div`
  display: flex;
  align-items: center;
  color: #78859d;
  flex: .5;
`;

export const DeploymentDate = styled.span`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  margin-right: 20px;
  color: ${colors.dark_grey};

  & > svg {
    color: ${colors.dark_grey};
    margin-right: 6px;
    width: 20px;
  }
`;
