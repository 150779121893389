import * as sc from "./style";
import * as Icons from "react-feather";

export const VersionUpload = ({handleChange, file}) => {
  const icon = file ? "CheckCircle" : "UploadCloud";
  const getIcon = (icon) => {
    const IconTag = Icons[icon];
    return (
      <IconTag />
    );
  };

  return (
    <sc.Container htmlFor="uploadPublicZip">
      {getIcon(icon)}
      <sc.Spacer height="4px"/>
      <sc.Input 
        id="uploadPublicZip"
        type="file"
        accept=".zip"
        onChange={(e) => handleChange(e.target.files[0])}      
      ></sc.Input>
    </sc.Container>
  )
}