import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { EmptyState } from "../../components/EmptyState";
import { Button } from "../../components/Button/Button";
import * as sc from "./style";

export default function NotFound() {
  const history = useHistory();
  const { t } = useTranslation();

  const handleRedirect = () => {
    history.push({ pathname: "/apps" });
  }

  return (
    <sc.Wrapper>
      <EmptyState 
        icon="🎈"
        title={t("404_title")}
        message={t("404_message")}
      />
      <sc.ButtonContainer>
        <Button
          primary={true}
          label={t("404_button")}
          onClick={() => handleRedirect()}
        />
      </sc.ButtonContainer>
    </sc.Wrapper>
  )
}
