import { Clock, Globe, Lock, Settings, Unlock, Zap } from "react-feather";
import * as sc from "./style";

export const PanelHeader = ({ data, handleChangeMainDescription }) => {
  const { name, description, usage, type, isCrypted } = data;

  return (
    <sc.Wrapper>
      <sc.Usage>
        {_getIcon(type, usage, isCrypted)}
      </sc.Usage>
      <sc.Info>
        <sc.Title>{name}</sc.Title>
        <sc.Spacer height="6px" />
        <sc.InputDescription
          type="description"
          onChange={handleChangeMainDescription}
          value={description}
          placeholder="Description"
        />
      </sc.Info>
    </sc.Wrapper>
  )
}

const _getIcon = (type, usage, isCrypted) => {
  if (type === "actions") {
    if (usage.length === 1 && usage[0] === "workflow") {
      return <sc.IconAction type={usage[0]}><Settings /></sc.IconAction>
    }
    if (usage.length === 1 && usage[0] === "webService") {
      return <sc.IconAction type={usage[0]}><Globe /></sc.IconAction>
    }
      
    if (usage.length === 2) {
      return (
        <sc.DoubleIconContainer>
          <sc.DoubleIconTopAction type="workflow">
            <Settings />
          </sc.DoubleIconTopAction>
          <sc.DoubleIconBottomAction type="webService">
            <Globe />
          </sc.DoubleIconBottomAction>
        </sc.DoubleIconContainer>
      )
    }
  }

  if (type === "events") {
    if (usage.length === 1 && usage[0] === "webhook") {
      return <sc.IconEvent type={usage[0]}><Zap /></sc.IconEvent>
    }
    if (usage.length === 1 && usage[0] === "scheduled") {
      return <sc.IconAction type={usage[0]}><Clock /></sc.IconAction>
    }
      
    if (usage.length === 2) {
      return (
        <sc.DoubleIconContainer>
          <sc.DoubleIconTopEvent type="webhook">
            <Zap />
          </sc.DoubleIconTopEvent>
          <sc.DoubleIconBottomEvent type="scheduled">
            <Clock />
          </sc.DoubleIconBottomEvent>
        </sc.DoubleIconContainer>
      )
    }
  }

  if (type === "contexts") {
    if (isCrypted) {
      return <sc.IconContext isCrypted={true}><Lock /></sc.IconContext>
    } else {
      return <sc.IconContext isCrypted={false}><Unlock /></sc.IconContext>
    }
  }
}