import * as sc from "./style";
import { useTranslation } from "react-i18next";
import { AlertTriangle, ArrowRight, Clock } from "react-feather";

export const DeploymentBanner = ({ status, version }) => {
  const { t } = useTranslation();

  if (status === "failed") {
    return (
      <sc.Banner status={status}>
        <sc.ContentContainer>
          <sc.IconContainer status={status}>
            <AlertTriangle />
          </sc.IconContainer>
          <sc.Content>
            {t("deployment_version_failed", { version })}
          </sc.Content>
        </sc.ContentContainer>
        <sc.ArrowContainer>
          <ArrowRight />
        </sc.ArrowContainer>
      </sc.Banner>
    )
  }

  return (
    <sc.Banner status={status}>
      <sc.ContentContainer>
        <sc.IconContainer status={status}>
          <Clock />
        </sc.IconContainer>
        <sc.Content>
          {t("deployment_version_running", { version })}
        </sc.Content>
      </sc.ContentContainer>
      <sc.ArrowContainer>
        <ArrowRight />
      </sc.ArrowContainer>
    </sc.Banner>
  )
}