import { useTranslation } from "react-i18next";
import { Button } from "../../../../Button/Button";
import * as sc from "../../style";
import { AppSelect } from "../../../../AppsSelect";

export default function View({ handleNextClick, handlePreviousClick, displayedStep, maxStep, versionsChoices, handleChangeSelectedVersion, nextVersion, selected }) {
  const { t } = useTranslation();
  return (
    <sc.Container>
      <sc.Title>{t("create_new_version_btn")}</sc.Title>
      <sc.SubTitle>{t("step_of", { num: displayedStep, max: maxStep })}</sc.SubTitle>
      <sc.H2>{t("version_change_select")}</sc.H2>
      <AppSelect 
        versions={versionsChoices} 
        handleChange = {handleChangeSelectedVersion}
        selected={selected}
      />
      <sc.TagWrapper>
        <sc.H2>{t("version_change_version")}</sc.H2>
        <sc.Tag>{nextVersion}</sc.Tag>
      </sc.TagWrapper>
      <sc.BtnContainer>
        <sc.Previous
          onClick={handlePreviousClick}
        >
          {t("previous")}
        </sc.Previous>
        <Button
          label={t("next")}
          onClick={handleNextClick}
          small={true}
          disabled={!selected}
        /> 
      </sc.BtnContainer>
    </sc.Container>
  )
}