import styled from "styled-components";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { colors } from "../../../helpers/style/constants";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ContentWrapper = styled.div`
  margin-left: 30px;
`;

export const Spacer = styled.div`
  height: ${p => p.height};
`;

export const Usage = styled.div`
`;

export const Info = styled.div`
  margin-left: 20px;
`;

export const Title = styled.h1`
  font-weight: 500;
  font-size: 20px;
  color: ${colors.dark_blue};
  margin: -2px 0;
`;

export const InputDescription = styled.input`
  border: 1px solid ${colors.light_grey};
  border-radius: 6px;
  color: ${colors.marjory_blue};
  font-size: 15px;
  font-weight: 400;
  height: 44px;
  outline: none;
  padding: 0 10px;
  transition: .2s ease-out;
  width: 514px;
  
  &::placeholder {
    font-weight: 400;
  }

  &:focus {
    border: 1px solid ${colors.marjory_blue};
  }
`;

export const IconAction = styled.div`
  background-color: ${p => p.type === "workflow"
    ? colors.workflow_background 
    : colors.web_service_background};
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 90px;

  & > svg {
    color: ${p => p.type === "workflow" ? colors.workflow_icon : colors.web_service_icon};
    width: 32px;
    height: 32px;
  }
`;

export const IconEvent = styled.div`
  background-color: ${p => p.type === "webhook"
    ? colors.workflow_background 
    : colors.web_service_background};
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 90px;

  & > svg {
    color: ${p => p.type === "webhook" ? colors.workflow_icon : colors.web_service_icon};
    width: 32px;
    height: 32px;
  }
`;

export const IconContext = styled.div`
  background-color: ${p => p.isCrypted
    ? colors.web_service_background
    : colors.workflow_background};
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 90px;

  & > svg {
    color: ${p => p.isCrypted ? colors.web_service_icon : colors.workflow_icon};
    width: 32px;
    height: 32px;
  }
`;

export const DoubleIconContainer = styled.div`
  position: relative;
  width: 94px;
  height: 94px;
`;

export const DoubleIconTopAction = styled.div`
  background-color: ${p => p.type === "workflow" 
    ? colors.workflow_background 
    : colors.web_service_background};
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;

  & > svg {
    color: ${p => p.type === "workflow" ? colors.workflow_icon : colors.web_service_icon};
    width: 20px;
  }
`;

export const DoubleIconTopEvent = styled.div`
  background-color: ${p => p.type === "webhook" 
    ? colors.workflow_background 
    : colors.web_service_background};
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;

  & > svg {
    color: ${p => p.type === "webhook" ? colors.workflow_icon : colors.web_service_icon};
    width: 20px;
  }
`;

export const DoubleIconBottomAction = styled.div`
  background-color: ${p => p.type === "workflow" 
    ? colors.workflow_background 
    : colors.web_service_background};
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  position: absolute;
  right: 0px;
  bottom: 0px;

  & > svg {
    color: ${p => p.type === "workflow" ? colors.workflow_icon : colors.web_service_icon};
    width: 20px;
  }
`;

export const DoubleIconBottomEvent = styled.div`
  background-color: ${p => p.type === "webhook" 
    ? colors.workflow_background 
    : colors.web_service_background};
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  position: absolute;
  right: 0px;
  bottom: 0px;

  & > svg {
    color: ${p => p.type === "webhook" ? colors.workflow_icon : colors.web_service_icon};
    width: 20px;
  }
`;

export const Container = styled(Tabs)`
  font-family: "Manrope",serif;
`;

export const List = styled(TabList)`
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
  color: ${colors.secondary_text};
  font-weight: 500;
  font-size: 18px;
  transition: .2s ease-in;

  .react-tabs__tab--selected {
    color: ${colors.text};
  }
`;

export const TabItem = styled(Tab)`
  margin-right: 40px;
  cursor: pointer;
`;

export const Panel = styled(TabPanel)`

`;

export const TriggerWrapper = styled.div`
  cursor: pointer;
  padding: 12px 12px;
  border-radius: 8px;
  transition: .2s ease-out;
  
  &:hover {
    background-color: ${colors.very_light_purple};
  }
`;

export const TriggerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    display: flex;
    align-items: center;
  }
`;

export const TriggerContent = styled.div`
  margin-left: 20px;
`;

export const TriggerIcon = styled.div`
  background-color: #fff1eb;
  border: 2px solid #fff1eb;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  color: #d63333;
  font-weight: 500;
  font-size: 19px;
`;

export const InputName = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #161c39;
`;

export const InputType = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.dark_grey};
  text-transform: capitalize;
`;

export const Chevron = styled.div`
  & > svg {
    color: ${colors.dark_grey};
  }
`;

export const PanelAccordionWrapper = styled.div`
  margin-top: 24px;
`;

export const InputWrapper = styled.div`
  margin: 10px 0;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;

  input {
    font-weight: 400;
    font-size: 15px;
    color: ${colors.marjory_blue};

    &::placeholder {
      color: ${colors.dark_grey};
    }

    border: 1px solid ${colors.light_grey};

    &:focus {
      border: 1px solid ${colors.marjory_blue};
    }
  }
`;

export const Label = styled.label`
  flex: 1.1;
  display: inline-block;
  text-align: right;
  font-size: 15px;
  font-weight: 500;
  color: ${colors.dark_blue};
`;

export const Input = styled.input`
  flex: 5;
  margin: 12px;
  border: 1px solid #e4e8f0;
  border-radius: 6px;
  color: ${colors.dark_blue};
  font-family: "Manrope", serif;
  font-size: 15px;
  font-weight: 400;
  height: 44px;
  outline: none;
  padding: 0 10px;
  transition: .2s ease-out;
  
  &::placeholder {
    font-weight: 400;
  }
  &:focus {
    border: 1px solid ${colors.new_input_border_focus};
  }
`;

export const Select = styled.select`
  flex: 5;
  margin: 12px;
  border: 1px solid #e4e8f0;
  border-radius: 6px;
  color: #161c39;
  font-family: "Manrope", serif;
  font-size: 16px;
  font-weight: 700;
  height: 44px;
  outline: none;
  padding: 0 10px;
  transition: .2s ease-out;

  &:focus {
    border: 1px solid ${colors.new_input_border_focus};
  }
`;
