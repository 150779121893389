import { Check, X } from "react-feather";
import Tooltip from "react-simple-tooltip";
import * as sc from "./style";
import { css } from "styled-components";

export const DeploymentSteps = ({ step, status }) => {
  let step1 = "";
  let step2 = "";
  let step3 = "";
  const lastStep =  status === "Complete" ? "Complete" : status === "Failed" ? "Failed" : status === "Running" ? "Running" : "Complete";
  if (step === 3) {
    step1 = "Complete";
    step2 = "Complete";
    step3 = lastStep
  } else if (step === 2) {
    step1 = "Complete";
    step2 = lastStep
    step3 = "Waiting";
  } else {
    step1 = lastStep
    step2 = "Waiting";
    step3 = "Waiting";
  }

  const getIcon = step => {
    switch (step) {
      case "Complete":
        return (
          <sc.Step status={step}>
            <Check stroke="currentColor" strokeWidth="3" />
          </sc.Step>
        )
      case "Running":
        return (
          <sc.Spinner/>
        )
      case "Failed":
        return (
          <sc.Step status={step}>
            <X stroke="currentColor" strokeWidth="3" />
          </sc.Step>
        )
      default:
        return (
          <sc.Step status={step}>
          </sc.Step>
        )
    }
  }

  return (
    <sc.Wrapper>
      <Tooltip
        content="Content validation"
        background="#161c39"
        fadeDuration={200}
        fontFamily="Manrope"
        fontSize="14px"
        padding={10}
        radius={6}
        customCss={css`
          white-space: nowrap;
        `}
      >
        {getIcon(step1)}
      </Tooltip>
      <sc.Pipe ></sc.Pipe>
      <Tooltip
        content="Content deployment"
        background="#161c39"
        fadeDuration={200}
        fontFamily="Manrope"
        fontSize="14px"
        padding={10}
        radius={6}
        customCss={css`
          white-space: nowrap;
        `}
      >
        {getIcon(step2)}
      </Tooltip>
      <sc.Pipe></sc.Pipe>
      <Tooltip
        content="Version creation"
        background="#161c39"
        fadeDuration={200}
        fontFamily="Manrope"
        fontSize="14px"
        padding={10}
        radius={6}
        customCss={css`
          white-space: nowrap;
        `}
      >
        {getIcon(step3)}
      </Tooltip>
    </sc.Wrapper>
  )
}
