import { useContext } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { KeycloakContext } from "../../auth/AuthWrapper";
import { useTranslation } from "react-i18next";
import Spinner from "../../components/Loader/Spinner";
import { EmptyState } from "../../components/EmptyState";
import { MainWrapper, MainContainer } from "../../helpers/style/MainWrapper.style";
import NewCreateModal from "../../components/Modal/NewCreateModal";
import List from "../../components/List/List";
import Input from "../../components/Input/Input";
import * as sc from "./style";

export default function View({ isLoading, data, fetchMoreData, nextToken, handleChange }) {
  const { t } = useTranslation();
  const [keycloak] = useContext(KeycloakContext);

  if (isLoading) {
    return (
      <MainWrapper>
        <Spinner />
      </MainWrapper>
    );
  }

  return (
    <MainWrapper>
      <MainContainer>
            <sc.H2>Applications</sc.H2>
            <sc.TopContainer>
              <Input
                type="search"
                placeholder="Search by Name"
                onChange={handleChange}
                id="search-app"
              />
              {_canCreateApp(keycloak.userRoles) &&
                <sc.ButtonContainer>
                  <NewCreateModal />
                </sc.ButtonContainer>
              }
            </sc.TopContainer>
            {data.length > 0 && (
              <>
                <InfiniteScroll
                  dataLength={data.length}
                  next={fetchMoreData}
                  hasMore={nextToken}
                >
                  <List items={data} />
                </InfiniteScroll>
              </>
            )}

            {!data.length && (
              // NOTE if no module display placeholder
              <>
                <EmptyState
                  icon="📦"
                  title={t("empty_title")}
                  message={t("empty_message")}
                />
              </>
            )}

      </MainContainer>
    </MainWrapper>
  );
}

const _canCreateApp = roles => {
  return roles.includes("services-create");
}