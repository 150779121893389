import styled from "styled-components";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { colors } from "../../helpers/style/constants";

export const Container = styled(Tabs)`
`;

export const List = styled(TabList)`
  list-style-type: none;
  display: flex;
  padding: 0 0 5px 0;
  margin: 0;
  color: ${colors.secondary_text};
  font-weight: 500;
  font-size: 16px;
  transition: .2s ease-in;

  .react-tabs__tab--selected {
    color: ${colors.text};
  }
`;

export const TabItem = styled(Tab)`
  margin-right: 40px;
  cursor: pointer;
`;

export const Panel = styled(TabPanel)`

`;
