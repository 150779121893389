import { useTranslation } from "react-i18next";
import MultiStepModal from "../../MultiStepModal";
import { Step1 } from "./components/Step1";
import { Step2 } from "./components/Step2";

export default function NewCreateModal() {
  const { t } = useTranslation();

  return (
    <MultiStepModal 
      title={t("create_new_app")}
      openModalTitle={t("create_new_app")}
    >
      <Step1/>
      <Step2/>
    </MultiStepModal>
  )
}