import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { colors } from "../../helpers/style/constants";

export const Nav = styled.nav`
  background: linear-gradient(180deg, #18187a 0%, #020827 100%);
  display: flex;
  position: fixed;
  flex-direction: column; 
  padding: 15px;
  height: 100%;
  top: 68px;
  width: 208px;
  z-index: -1;

`;

export const NavItem = styled(NavLink)`
  display: flex;
  margin: 5px 0;
  padding: 7px 7px 7px 10px;
  border-radius: 25px;
  transition: 0.2s ease-out;
  text-decoration: none;
  font-weight: 400;
  color: ${colors.nav_label};
  align-items: center;
  text-transform: capitalize;

  div > svg {
    color: ${colors.light_purple};
  }

  &:hover {
    border-radius: 25px;
    background-color: white;
    color: ${colors.nav_label_active};
    svg {
      color: ${colors.nav_label_active};
    }
    div {
      color: ${colors.nav_label_active};
    }
  }
  
  &.active, &.active > div {
    background-color: white;
    color: ${colors.nav_label_active};
  }

  &.active, &.active > div > div > svg {
    background-color: white;
    color: ${colors.nav_label_active};
  }
`;

export const ExternalNavItem = styled.a`
  display: flex;
  margin: 1px 0;
  padding: 8px;
  border-radius: 6px;
  text-decoration: none;
  font-weight: 400;
  color: ${colors.nav_label};

  &.active, &.active > div {
    background-color: ${colors.nav_bg};
    color: ${colors.nav_label_active};
  }

  div > svg {
    color: ${colors.light_purple};
  }

  &:hover {
    border-radius: 25px;
    background-color: white;
    color: ${colors.nav_label_active};
    svg {
      color: ${colors.nav_label_active};
    }
    div {
      color: ${colors.nav_label_active};
    }
  }
`;

export const Logout = styled.div`
  display: flex;
  margin: 5px 0;
  padding: 5px;
  border-radius: 6px;
  transition: 0.2s ease-out;
  text-decoration: none;
  font-weight: 400;
  color: ${colors.nav_label_active};
  cursor: pointer;
`;

export const IconBox = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconTagBox = styled.div`
  color: ${colors.icon};
  display: flex;
  width: 20px;
  transform: ${p => p.rotate
    ? "rotate(180deg)"
    : "none"
  };
`;

export const Spacer = styled.hr`
  border: none;
  border-top: 1px solid ${colors.nav_spacer};
  width: 100%;
  margin: 12px 0;
`;

export const NavLabel = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  margin-left: 10px;
  font-family: "Manrope",serif;
  font-size: 15px;
  color: ${colors.nav_label};
  transition: 0.2s ease-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover, &.active {
    color: ${colors.nav_label_active};
  }
`;

export const BottomContainer = styled.div`
  position: fixed;
  bottom: 20px;
  width: 300px;
`;

export const ButtonContainer = styled.div`
  margin: 30px 0;
`;
