import { Globe, Loader, Settings } from "react-feather";
import * as sc from "./ActionIcon.style";

export const ActionIcon = ({ list }) => {
  if (list.length === 1) {
    return (
      <sc.OneUsage>
        <sc.Box usage={list[0]} size="lg">
          {icon(list[0])}
        </sc.Box>
      </sc.OneUsage>
    )
  }

  if (list.length === 2) {
    return (
      <sc.TwoUsages>
        <sc.Box usage={list[0]} size="md">
          {icon(list[0])}
        </sc.Box>
        <sc.Box usage={list[1]} size="md">
          {icon(list[1])}
        </sc.Box>
      </sc.TwoUsages>
    )
  }

  if (list.length === 3) {
    return (
      <sc.ThreeUsages>
        <sc.Box usage={list[0]} size="sm">
          {icon(list[0])}
        </sc.Box>
        <sc.Box usage={list[1]} size="sm">
          {icon(list[1])}
        </sc.Box>
        <sc.Box usage={list[2]} size="sm">
          {icon(list[2])}
        </sc.Box>
      </sc.ThreeUsages>
    )
  }
}

const icon = (usage) => {
  if (usage === "workflow") {
    return <Settings />
  }

  if (usage === "webService") {
    return <Globe />
  }

  if (usage === "external") {
    return <Loader />
  }
}