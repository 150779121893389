import axios from "axios";
import { getConfig } from "../../../../../helpers/getConfig";

const API_URL = process.env.REACT_APP_FACTORY_API_URL;

export const createIcon = async (id, base64Icon, token) => {
  try {
    const config = getConfig(token);
    return await axios.post(`${API_URL}/applications/${id}/icon`, { base64Icon }, config);
  } catch(err) {
    console.error(err);
    throw err;
  }
}