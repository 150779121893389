import styled from "styled-components";
import { colors } from "../../../helpers/style/constants";
import AvatarEditor from "react-avatar-editor";

export const Container = styled.div`
  width: 530px;
  margin: 30px 0 20px 0;
  display: grid;
`;

export const Title = styled.h2`
  display: flex;
  margin: 15px 0;
  font-size: 20px;
  color: ${colors.text};
  font-weight: 500;
`;

export const SubTitle = styled.h3`
  display: flex;
  font-size: 17px;
  color: ${colors.secondary_text};
  margin-top: 0px;
  font-weight: 400;
`;

export const H2 = styled.h2`
  font-size: 15px;
  color: ${colors.marjory_blue};
  margin-bottom: 5px;
  font-weight: 500;
`;

export const Previous = styled.button`
  background-color: transparent;
  border: none;
  color: #78859d;
  font-size: 16px;
  font-weight: 500;
  transition: .2s ease-out;

  &:hover {
    color: #020253;
  }
`;

export const BtnContainer = styled.div`
  display: flex;
  justify-content: ${p => p.right ? "flex-end" : "space-between"};
  margin-top: 50px;
`;

export const Spacer = styled.div`
  width: ${p => p.width ? p.width : ""};
  height: ${p => p.height ? p.height : ""};
`;

export const Error = styled.p`
  color: #e45655;
  font-weight: 600;
  font-size: 17px;
  margin: 3px 0 -34px;
`;

export const LengthCounterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 18px;
`;

export const LengthCounter = styled.div`
  margin-top: 12px;
  position: absolute;
  font-weight: 500;
  float: right;
`;

export const Spinner = styled.div`
  padding: ${p => p.large ? "25px" : "9px"};
  font-size: 10px;
  animation: load8 .9s infinite linear;
  transform: translateZ(0);
  border: 2px solid rgba(255, 255, 255, .8);
  border-left: 2px solid #020253;
  border-radius: 68px;
  width: fit-content;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
`;

export const CenteringImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 24px;
`;

export const AppInitial = styled.span`
  font-size: 36px;
  font-weight: 500;
`;

export const ImageBorderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  width: 150px;
  height: 150px;
  background-color: ${p => p.image ? "#e4e8f0" : "#027aff"};
  clip-path: polygon(45% 1.33975%, 46.5798% 0.60307%, 48.26352% 0.15192%, 50% 0%, 51.73648% 0.15192%, 53.4202% 0.60307%, 55% 1.33975%, 89.64102% 21.33975%, 91.06889% 22.33956%, 92.30146% 23.57212%, 93.30127% 25%, 94.03794% 26.5798%, 94.48909% 28.26352%, 94.64102% 30%, 94.64102% 70%, 94.48909% 71.73648%, 94.03794% 73.4202%, 93.30127% 75%, 92.30146% 76.42788%, 91.06889% 77.66044%, 89.64102% 78.66025%, 55% 98.66025%, 53.4202% 99.39693%, 51.73648% 99.84808%, 50% 100%, 48.26352% 99.84808%, 46.5798% 99.39693%, 45% 98.66025%, 10.35898% 78.66025%, 8.93111% 77.66044%, 7.69854% 76.42788%, 6.69873% 75%, 5.96206% 73.4202%, 5.51091% 71.73648%, 5.35898% 70%, 5.35898% 30%, 5.51091% 28.26352%, 5.96206% 26.5798%, 6.69873% 25%, 7.69854% 23.57212%, 8.93111% 22.33956%, 10.35898% 21.33975%);
`;

export const ImageContainer = styled(AvatarEditor)`
  background-color: #027aff;
  clip-path: polygon(45% 1.33975%, 46.5798% 0.60307%, 48.26352% 0.15192%, 50% 0%, 51.73648% 0.15192%, 53.4202% 0.60307%, 55% 1.33975%, 89.64102% 21.33975%, 91.06889% 22.33956%, 92.30146% 23.57212%, 93.30127% 25%, 94.03794% 26.5798%, 94.48909% 28.26352%, 94.64102% 30%, 94.64102% 70%, 94.48909% 71.73648%, 94.03794% 73.4202%, 93.30127% 75%, 92.30146% 76.42788%, 91.06889% 77.66044%, 89.64102% 78.66025%, 55% 98.66025%, 53.4202% 99.39693%, 51.73648% 99.84808%, 50% 100%, 48.26352% 99.84808%, 46.5798% 99.39693%, 45% 98.66025%, 10.35898% 78.66025%, 8.93111% 77.66044%, 7.69854% 76.42788%, 6.69873% 75%, 5.96206% 73.4202%, 5.51091% 71.73648%, 5.35898% 70%, 5.35898% 30%, 5.51091% 28.26352%, 5.96206% 26.5798%, 6.69873% 25%, 7.69854% 23.57212%, 8.93111% 22.33956%, 10.35898% 21.33975%);
`;

export const UploadButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const UploadButton = styled.label`
  margin: 0 10px;
  background-color: ${p => p.preview ? "#fff" : "#020253"};
  border: 1px solid #020253;
  border-radius: 6px;
  color: ${p => p.preview ? "#020253" : "#fff"};
  cursor: ${p => p.isLoading ? 'auto' : 'pointer'};
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 400;
  max-width: inherit;
  outline: 0;
  overflow: hidden;
  padding: 15px 40px;
  position: relative;
  text-decoration: none;
  text-overflow: ellipsis;
  width: 130px;
  white-space: nowrap;
  -webkit-tap-highlight-color: transparent;
  text-align: center;
`;

export const InputFile = styled.input`
  display: none;
`;

export const Skip = styled.button`
  background-color: transparent;
  border: none;
  color: ${colors.dark_grey};
  font-size: 16px;
  font-weight: 500;
  -webkit-transition: .2s ease-out;
  transition: .2s ease-out;

  &:hover {
    color: #020253;
  }
`;
