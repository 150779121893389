import styled from "styled-components";
import { colors } from "../../../helpers/style/constants";

export const Container = styled.div`
  text-align: center;
  width: 540px !important;
`;

export const Icon = styled.div`
  background-color: ${colors.blue_light};
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 90px;

  & > svg {
    color: ${colors.blue};
    width: 32px;
    height: 32px;
  }
`;

export const Spacer = styled.div`
  width: ${p => p.width ? p.width : ""};
  height: ${p => p.height ? p.height : ""};
`;

export const Title = styled.h2`
  font-size: 18px;
  margin-bottom: 24px;
  color: ${colors.text};
`;

export const Content = styled.p`
  display: flex;
  font-size: 16px;
  font-weight: 500;
  color:${colors.secondary_text};
  line-height: 1.6;
`;

export const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

export const Spinner = styled.div`
  margin: -3px auto;
  padding: 11px;
  font-size: 10px;
  animation: load8 .9s infinite linear;
  transform: translateZ(0);
  border: 3px solid rgba(255, 255, 255, 0.2);
  border-left: 3px solid #fff;
  border-radius: 68px;

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;