import styled from "styled-components";
import { colors } from "../../helpers/style/constants";

export const Paragraph = styled.p`
  white-space: pre-wrap;
  font-weight: 400;
  font-size: 15px;
  color: ${colors.text};
  margin: -8px;
  padding: 8px;
  line-height: 1.5;
  cursor: pointer;
  transition: 0.2s ease-out;
  border-radius: 8px;

  &:hover {
    background-color: ${colors.very_very_light_grey};
  }
`;

export const ShowMoreBtn = styled.p`
  color: ${colors.blue};
  cursor: pointer;
  margin: 10px 0 0;
  font-weight: 500;
  width: fit-content;
  font-size: 15px;
  
  &:hover {
    text-decoration: underline;
  }
`;
