import * as sc from "./style";
import { DeploymentItem } from '../../DeploymentItem';

export const DeploymentsList = ({ items: deployments, retryDeployment, isRetrying }) => {
  return (
    <sc.ListContainer>
      {deployments.map((deployment, index) => {
        return (
          <DeploymentItem 
            key={index} 
            deployment={deployment} 
            retryDeployment={retryDeployment} 
            isRetrying={isRetrying} 
          />
        )
      })}
    </sc.ListContainer>
  );
};