export const getConfig = (token) => {
  return {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }
}

export const getUploadConfig = (token, params) => {
  return {
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": `multipart/form-data; charset=utf-8; boundary=${params._boundary}`
    }
  }
}
