import { useState } from "react";
import Collapsible from "react-collapsible";
import { ChevronRight, ChevronDown } from "react-feather";
import * as sc from "./style";
import "./style.css";

export const PanelAccordion = ({ index, input, path, handleChange }) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const handleTrigger =() => {
    setIsPanelOpen(!isPanelOpen);
  }

  return (
    <Collapsible
      transitionTime="150"
      easing="ease-out"
      onTriggerOpening={handleTrigger}
      onTriggerClosing={handleTrigger}
      trigger={<Trigger index={index} input={input} isPanelOpen={isPanelOpen} />}
    >
      <sc.ContentWrapper>
      <Content
        index={index}
        path={path}
        input={input}
        handleChange={handleChange}
      />
        </sc.ContentWrapper>
    </Collapsible>
  );
};

const Trigger = ({ index, input, isPanelOpen }) => {
  const getInputIcon = type => {
    switch (type) {
      case "string":
        return "ABC";
      case "number":
        return "123";
      case "boolean":
        return "T/F";
      case "array":
        return "[ ]";
      default:
        return "{ }";
    }
  }

  return (
    <sc.TriggerWrapper>
      <sc.TriggerContainer>
        <div> 
          <sc.TriggerIcon>
            {getInputIcon(input.type)}
          </sc.TriggerIcon>
          <sc.TriggerContent>
            {input.name 
              ? <sc.InputName>{input.name}</sc.InputName>
              : <sc.InputName>Item {index + 1}</sc.InputName>
            }
            <sc.InputType>{input.type}</sc.InputType>
          </sc.TriggerContent>
        </div>
        <sc.Chevron>
          {isPanelOpen
            ? <ChevronDown />
            : <ChevronRight />
          }
        </sc.Chevron>
      </sc.TriggerContainer>
    </sc.TriggerWrapper>
  );
};

const Content = ({ index, input, path, handleChange }) => {
  if (!path) path = index;
  else path += `,${index}`;

  const displaySubElements = () => {
    let subElements = [];

    if (input.type === "object") {
      subElements = input.properties;
      path += ",properties";
    }
    else if (input.type === "array") {
      subElements = input.items
      path += ",items";
    } 

    return (
      <>
        {/* Show properties if object */}
        {["object", "array"].includes(input.type) &&
          subElements.map((subElement, index) => (
            <PanelAccordion
              key={index}
              path={path}
              index={index}
              input={subElement}
              handleChange={handleChange}
            />
          ))}
      </>
    );
  };

  return (
    <sc.InputWrapper>
      {/* Description of the input */}
      <sc.InputContainer>
        <sc.Label>Description :</sc.Label>
        <input
          className="input"
          type="description"
          path={path}
          value={input.description}
          onChange={handleChange}
          placeholder="Description"
        />
      </sc.InputContainer>

      {/* Show example if not boolean / object / array */}
      {["string", "number"].includes(input.type) && (
        <sc.InputContainer>
          <sc.Label>Example :</sc.Label>
          <input
            className="input"
            type="example"
            path={path}
            value={input.example}
            onChange={handleChange}
            placeholder="Example"
          />
        </sc.InputContainer>   
      )}

      {/* Show example if boolean */}
      {["boolean"].includes(input.type) && (
        <sc.InputContainer>
          <sc.Label>Example :</sc.Label>
          <select
            className="select"
            type="example"
            path={path}
            onChange={handleChange}
            defaultValue={input.example === true ? true : false}
          >
            <option value={true}>True</option>
            <option value={false}>False</option>
          </select>
        </sc.InputContainer>   
      )}
      {displaySubElements()}
    </sc.InputWrapper>
  );
};