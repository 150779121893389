import styled from "styled-components";

export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const H2 = styled.h2`
  margin: 20px 40px;
  font-size: 24px;
  color: #020827;
  font-weight: 600;  
`;
