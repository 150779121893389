import { useState } from "react";
import { useTranslation } from "react-i18next";
import MultiStepModal from "../../MultiStepModal";
import { Step1 } from "./components/Step1";
import { Step2 } from "./components/Step2";
import { Step3 } from "./components/Step3";
import { Step4 } from "./components/Step4";

export const CreateVersionModal = ({versions, appId, isDisabled}) => {
  const { t } = useTranslation();
  const [versionType, setVersionType] = useState("major");

  const selectVersionType = (versionType) => {
    setVersionType(versionType);
  };

  return (
    <MultiStepModal
      title={t("create_new_version_btn")}
      openModalTitle={t("create_new_version_btn")}
      isDisabled={isDisabled}
    >
      <Step1 selectVersionType={selectVersionType}/>
      <Step2 versions={versions} changeType={versionType}/>
      <Step3/>
      <Step4 appId={appId} />
    </MultiStepModal>
  )
}