import styled from "styled-components";
import { colors } from "../../helpers/style/constants";

export const Wrapper = styled.div`
  color: ${colors.text};
  width: 530px;
`;

export const AppContent = styled.div`
  display: flex;
  align-items: center;
`;

export const Name = styled.h2`
  margin-bottom: 0;
  display: flex;
  font-size: 20px;
  text-transform: capitalize;
`;

export const ErrorVersion = styled.h3`
  margin-top: 0;
  font-size: 16px;
  color: ${colors.deployment_tag_failed_label};
  font-weight: 600;
`;

export const Spacer = styled.div`
  height: ${p => p.height};
`;

export const AppTitle = styled.div`
  margin-left: 20px;
`;

export const ErrorTitle = styled.p`
  margin: 0;
  font-weight: 600;
`;

export const ErrorElement = styled.li`
  font-size: 16px;
  color: #78859d;
  margin: 10px 0;
`;

export const ButtonContainer = styled.div`
  width: 310px;
`;

export const UploadButton = styled.label`
  background-color: ${p => p.preview ? "#fff" : "#020253"};
  border: 1px solid #020253;
  border-radius: 6px;
  color: ${p => p.preview ? "#020253" : "#fff"};
  cursor: ${p => p.isLoading ? 'auto' : 'pointer'};
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  max-width: inherit;
  outline: 0;
  overflow: hidden;
  padding: 15px 40px;
  position: relative;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-tap-highlight-color: transparent;
  text-align: center;
`;

export const InputFile = styled.input`
  display: none;
`;

export const Spinner = styled.div`
  padding: ${p => p.large ?  "25px" : "9px"};
  font-size: 10px;
  animation: load8 .9s infinite linear;
  transform: translateZ(0);
  border: 2px solid rgba(255, 255, 255, .8);
  border-left: 2px solid #020253;
  border-radius: 68px;
  width: fit-content;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
`;
