import { Link as a } from "react-router-dom";
import { colors } from "../../helpers/style/constants"
import styled from "styled-components";

export const ListContainer = styled.ul`
  list-style-type: none;
  margin-top: 30px;
  padding-left: 0;
`;

export const Link = styled(a)`
  color: ${colors.link};
  cursor: pointer;
  text-decoration: none;
  display: flex;
  flex: 1;
  align-items: center;
  min-width: 276px;

  &:hover{
    p {
      color: ${colors.nav_label_active};
    }
  }
`;

export const ListItem = styled.li`
  border-left: 1px solid ${colors.list_border};
  border-right: 1px solid ${colors.list_border};
  transition: 0.2s ease-out;
  padding: 10px 20px;
  display: flex;
  &:first-child{
    border-top: 1px solid ${colors.list_border};
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &:last-child{
    border-bottom: 1px solid ${colors.list_border};
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  &:not(first-child), &:not(last-child){
    border-bottom: 1px solid ${colors.list_border};
  }
  &:hover{
    background-color: ${colors.very_light_purple};
  }
`;

export const LeftBlock = styled.div`
  display: contents;
`;

export const RightBlock = styled.div`
  display: flex;
  flex: .7;
  align-items: center;
  justify-content: flex-end;
`;

export const ModuleName = styled.div`
  font-size: 15px; 
  text-transform: capitalize;
  margin-left: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;

  & > p {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 60px;
`;

export const DeploymentInProgress = styled.div`
  display: flex;
  align-items: center;
  margin-right: 75px;
  margin-left: 60px;
`;

export const InfoText = styled.p`
  margin-left: 10px;
`;

export const Category = styled.div`
  font-size: 15px;
  color: ${colors.list_actions_count};
  white-space: nowrap;
  font-weight: 500;
`;

export const updatedAt = styled.span`
  font-size: 15px;
  color: ${colors.list_actions_count};
  white-space: nowrap;
  font-weight: 500;
`;

export const RightBlockMargin = styled.div`
  margin-left: 20px;
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const UploadButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${colors.secondary_btn_border};
    border-radius: 8px;
    background-color: #fff;
    color: ${colors.secondary_btn_text};
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
    min-width: 200px;
    outline: none;
    padding: 14px 0;
`;

export const Rotate = styled.div`
  animation: rotation 3s infinite linear;
  @-webkit-keyframes rotation {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotation {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

export const Spinner = styled.div`
  margin: 0 auto;
  padding: 9px;
  font-size: 10px;
  animation: load8 .9s infinite linear;
  transform: translateZ(0);
  border: 3px solid rgba(255, 255, 255, 0.2);
  border-left: 3px solid #020253;
  border-radius: 68px;

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

export const MiddleBlock = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.dark_grey};
  flex: .25;
`;

export const  VerticalSpacer = styled.div`
  margin-right: 20px;
`;

export const AppStat = styled.span`
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 500;

  & > svg {
    color: ${colors.dark_grey};
    margin-right: 6px;
    width: 20px;
  }
`;
