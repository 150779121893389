import * as service from "./service";

import { useContext, useEffect, useState } from "react";

import { KeycloakContext } from "../../auth/AuthWrapper";
import View from "./View";
import { useHistory } from "react-router-dom";

import "./style.css";

export default function Applications() {
  const history = useHistory();
  const [state] = useContext(KeycloakContext);
  const [data, setData] = useState({});
  const [nextToken, setNextToken] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  // NOTE Triggered when the page is loaded
  useEffect(() => {
    !state.userRoles && history.push("/error/500");
  }, [state.userRoles, history]);

  const token = state.keycloak.token;

  useEffect(() => {
    fetchData(null);
  }, []);

  const fetchData = async (searchName) => {
    const { apps, nextPageToken } = await service.listApplicationsWithPagination(token, null, searchName);
    setData(apps);
    setNextToken(nextPageToken);
    setIsLoading(false);
  }

  const fetchMoreData = async () => {
    const { apps, nextPageToken } = await service.listApplicationsWithPagination(token, nextToken);
    setData([...data, ...apps]);
    setNextToken(nextPageToken);
  }

  const handleChange = async (e) => {
    e?.target.value.length > 2
      ? fetchData(e.target.value)
      : fetchData(null);
  }

  // NOTE Rendering component
  const params = { isLoading, data, fetchMoreData, nextToken, handleChange };
  return <View {...params} />
}