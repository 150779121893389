import * as sc from "./style";
import { Users } from "react-feather";
import Button from "../../Button/Button";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";

export const PublishAppModal = ({ open, onCloseModal, handlePublish, isPublishing }) => {
  return (
    <Modal 
      open={open} 
      onClose={onCloseModal} 
      center
      classNames={{
        overlay: "custom-overlay",
        modal: "custom-modal"
      }}
    >
      <sc.Container>
        <sc.Spacer height="40px" />
        <sc.Icon><Users /></sc.Icon>
        <sc.Spacer height="20px" />
        <sc.Title>Make the application public</sc.Title>
        <sc.Content>By clicking on the "Confirm" button, you will make the version of this application available to all Marjory users.</sc.Content>
        <sc.BtnContainer>
          <Button
            onClick={!isPublishing ? handlePublish : undefined}
            label={isPublishing ? <sc.Spinner/> : "Confirm"}
            small
          />
        </sc.BtnContainer>
        <sc.Spacer height="20px" />
      </sc.Container>
    </Modal>
  )
}