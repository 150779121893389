import styled from "styled-components";
import { colors } from "../../helpers/style/constants"

export const Banner = styled.div`
  cursor: pointer;
  font-weight: 600;
  color: ${p => p.status !== "failed" ? colors.deployment_tag_running_label : colors.deployment_tag_failed_label};
  background-color: ${p => p.status !== "failed" ? colors.deployment_tag_running_bg : colors.deployment_tag_failed_bg};
  border-radius: 8px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 15px;
`;

export const ArrowContainer = styled.div`
  display: flex;
  align-items: center;

  & > svg {
    width: 22px;
    height: 22px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Content = styled.div`
  margin-left: 20px;
`;

export const IconContainer = styled.div`
  margin: 0;
  min-width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${p => p.status !== "failed" ? colors.deployment_tag_running_label : colors.deployment_tag_failed_label};
  border-radius: 8px;

  & > svg {
    width: 22px;
    height: 22px;
    color: #fff;
  }
`;
